import React from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow} from '@react-google-maps/api';

const libraries = ['places'];

export default function Maps() {
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: "AIzaSyBSpPOkcxdRfhu_6mxA21TnrckzcwfuFPo",
        libraries,
    });

    const containerStyle = {
        position: 'relative',
        width: '100%',
        height: '100%',
        paddingTop: '396px'
    }

    if (loadError) return "Błąd ładowania mapy";
    if(!isLoaded) return "Ładowanie mapy.";
      return (
          <GoogleMap mapContainerStyle = {containerStyle} zoom={13} center={{ lat: 52.0921461, lng: 15.6099101 }}>
              <Marker
                  position={{
                      lat: 52.0921461,
                      lng: 15.6099101
                  }}
                  text={"Łochowska 6A, 66-100 Sulechów"}
              />
          </GoogleMap>
      );
}
