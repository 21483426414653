import React from 'react';
import {GlobalStyle} from './GlobalStyle';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './components/Home';
import News from './components/News';
import Products from './components/Products';
import Wideo from './components/Wideo';
import Shop from './components/Shop';
import Contact from './components/Contact';
import Category from './components/Category';
import ProductContainer from './components/ProductContainer';
import Advertisement from './components/Advertisement';
import ScrollToTopOnChange from './components/ScrollToTopOnChange';
import Privacy from "./components/Privacy";
import Work from "./components/Work";
import ContestRegulations from "./components/ContestRegulations";

const App = () => (
  <Router>
    <ScrollToTopOnChange />
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/News' element={<News/>}/>
      <Route path='/Products' element={<Products/>}/>
      <Route path='/Wideo' element={<Wideo/>}/>
      <Route path='/Shop' element={<Shop/>}/>
      <Route path='/Contact' element={<Contact/>}/>
      <Route path='/Katalog' element={<Advertisement />}/>
      <Route path='/Prywatnosc' element={<Privacy />}/>
      <Route path='/RegulaminKonkursu2023' element={<ContestRegulations />}/>
      {/*<Route path='/Praca' element={<Work />}/>*/}
      <Route path='/Product/:id' element={<ProductContainer/>}/>
      <Route path='/Products/:title' element={<Category/>}/>
    </Routes>
    <GlobalStyle />
  </Router>
)

export default App;
