import React from 'react';
import {Link} from 'react-router-dom';
import LorLogo from '../../images/logoblue.svg';

import { BiggerWrapper, Wrapper, Content, LogoLorpio, NewestText, ProductsText, VideoText, FindText, AdText, ContactText } from './Topbar.styles';
import {Text} from "../Footer/Footer.styles";

const Topbar = (clickable) => (
    <BiggerWrapper>
        <Wrapper>
            <Content>
                <Link to='/' style={{ textDecoration: 'none' }}><LogoLorpio src={LorLogo} alt='logo'></LogoLorpio></Link>
                <Link to='/News' style={{ textDecoration: 'none' }}><NewestText>NOWOŚCI</NewestText></Link>
                <Link to='/Products' style={{ textDecoration: 'none' }}><ProductsText>PRODUKTY</ProductsText></Link>
                <Link to='/Wideo' style={{ textDecoration: 'none' }}><VideoText>FILMY</VideoText></Link>
                <Link to='/Katalog' style={{ textDecoration: 'none' }}><AdText>KATALOG</AdText></Link>
                <Link to='/Shop' style={{ textDecoration: 'none' }}><FindText>ZNAJDŹ SKLEP</FindText></Link>
                <Link to='/Contact' style={{ textDecoration: 'none' }}><ContactText>KONTAKT</ContactText></Link>
            </Content>
        </Wrapper>
    </BiggerWrapper>
    
);

export default Topbar;