import React, {useState} from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { ImgBorder, ImgBorderThumb, NewsImg } from './ProductContainer.styles';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import reactDom from 'react-dom';

const Swiperr = (props) =>{
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const slides = [];
    props.images.map((item, id)=>{
        slides.push(
            <SwiperSlide key={`slide-${item}`} style={{ display: 'flex', 'flex-direction':'row', 'align-content':'center', 'justify-content': 'center'}}>
                <ImgBorder style={{paddingBottom: '20px'}}>
                    <NewsImg onClick={() => { setIsModalOpen(true); setPhotoIndex(id) }} src={'https://lorpio-back.ang3r.pl'+item} alt='product_icon' />
                </ImgBorder>
            </SwiperSlide>
        )
    })

    const thumbs = [];
    props.images.map((item, id)=>{
        thumbs.push(
            <SwiperSlide key={`slide-${item}`} style={{ listStyle: 'none', height: '100%', display: 'flex', justifyContent: 'center' }}>
                <ImgBorderThumb>
                    <NewsImg src={'https://lorpio-back.ang3r.pl'+item} alt='product_icon' />
                </ImgBorderThumb>
            </SwiperSlide>
        )
    })

    return(
        <>
            {isModalOpen && (
            <Lightbox
                mainSrc={'https://lorpio-back.ang3r.pl'+props.images[photoIndex]}
                nextSrc={'https://lorpio-back.ang3r.pl'+props.images[(photoIndex + 1) % props.images.length]}
                prevSrc={'https://lorpio-back.ang3r.pl'+props.images[(photoIndex + props.images.length - 1) % props.images.length]}
                onCloseRequest={() => { setIsModalOpen(false) }}
                onMovePrevRequest={() => {
                    setPhotoIndex((photoIndex + props.images.length - 1) % props.images.length)
                }}
                onMoveNextRequest={() => {
                    setPhotoIndex((photoIndex + 1) % props.images.length)
                }}
            />
            )}
            <Swiper navigation pagination={{clickable: true}} 
                    // onSwiper={(swiper) => console.log(swiper) }
                    // onSlideChange={() => console.log('slide change')}
                    thumbs={{swiper: thumbsSwiper}}
                    style={{width:'100%', display: 'flex', 'flex-direction':'row' }}>
                {slides}
            </Swiper>
        
            <Swiper id="thumbs" spaceBetween={0}
            slidesPerView={3} onSwiper={setThumbsSwiper}
            style={{ width: '100%' }}
            >
                {thumbs}
            </Swiper>
        </>

    );
}

export default Swiperr;