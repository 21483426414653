import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  BiggerWrapper,
  Wrapper,
  Content,
  Text,
  Logosection,
  Socialsection,
  Contactsection,
  Toolbarsection,
  LogoYt,
  LogoFb,
  LogoIn,
  Arrow,
  LogoTt,
} from "./Footer.styles";
import LorLogo from "../../images/logo.svg";
import FbLogo from "../../images/LOGO_FACEBOOK.svg";
import InstLogo from "../../images/INSTA.svg";
import YtLogo from "../../images/youtube.png";
import ArrowUp from "../../images/arrow_up.png";
import TikTokLogo from "../../images/tiktok-logo.svg";
import Loading from "../Loading";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
    /* you can also use 'auto' behaviour
         in place of 'smooth' */
  });
};

function NewlineText(props) {
  const text = props.text;
  const newText = text.split("\n").map((str) => (
    <p
      style={{
        color: "transparent",
        textShadow: "0 0 0 var(--white)",
        margin: "0 0 -1px 0",
        fontSize: "0.8em",
      }}
    >
      {str}
    </p>
  ));

  return <div title="normal">{newText}</div>;
}

function NewlineTextNoWrap(props) {
  const text = props.text;
  let newText = text.replaceAll(": ", ":\n");
  newText = newText.split("\n").map((str) => (
    <p
      style={{
        color: "transparent",
        textShadow: "0 0 0 var(--white)",
        margin: "0 0 -1px 0",
        fontSize: "0.8em",
      }}
    >
      {str}
    </p>
  ));
  return <div title="nowrap">{newText}</div>;
}

const url = "https://lorpio-back.ang3r.pl/api/contact";
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      numbers: "",
      fb: "",
      ig: "",
      yt: "",
      tt: "",
    };
  }
  async componentDidMount() {
    const response = await fetch(url);
    const data = await response.json();

    this.setState({
      numbers: data.contact.phoneNumbers,
      fb: data.contact.facebookURL,
      ig: data.contact.linkedinURL,
      yt: data.contact.youtubeURL,
      tt: data.contact.tiktokURL,
      isLoading: false,
    });
  }

  render() {
    return (
      <footer>
        <BiggerWrapper>
          <Wrapper>
            <Content>
              <Logosection src={LorLogo} alt="logo-white"></Logosection>

              <Contactsection>
                {this.state.isLoading ? (
                  <Loading color="var(--white)" noAdditionalHeight />
                ) : (
                  <>
                    <NewlineText
                      text={this.state.numbers}
                      style={{ display: "flex", flexDirection: "column" }}
                    />
                    <NewlineTextNoWrap
                      text={this.state.numbers}
                      style={{ display: "flex", flexDirection: "column" }}
                    />
                  </>
                )}
              </Contactsection>

              <Socialsection>
                <a href={this.state.fb}>
                  <LogoFb src={FbLogo} alt="fb-logo"></LogoFb>
                </a>
                <a href={this.state.ig}>
                  <LogoIn src={InstLogo} alt="ln-logo"></LogoIn>
                </a>
                <a href={this.state.yt}>
                  <LogoYt src={YtLogo} alt="yt-logo"></LogoYt>
                </a>
                <a href={this.state.tt}>
                  <LogoTt src={TikTokLogo} alt="tt-logo"></LogoTt>
                </a>
              </Socialsection>

              <Toolbarsection>
                <Link to="/News" style={{ textDecoration: "none" }}>
                  <Text>NOWOŚCI</Text>
                </Link>
                <Link to="/Products" style={{ textDecoration: "none" }}>
                  <Text>PRODUKTY</Text>
                </Link>
                <Link to="/Wideo" style={{ textDecoration: "none" }}>
                  <Text>FILMY</Text>
                </Link>
                <Link to="/Katalog" style={{ textDecoration: "none" }}>
                  <Text>KATALOG</Text>
                </Link>
                <Link to="/Shop" style={{ textDecoration: "none" }}>
                  <Text>ZNAJDŹ SKLEP</Text>
                </Link>
                <Link to="/Contact" style={{ textDecoration: "none" }}>
                  <Text>KONTAKT</Text>
                </Link>
                <Link to="/Prywatnosc" style={{ textDecoration: "none" }}>
                  <Text>POLITYKA PRYWATNOŚCI</Text>
                </Link>
              </Toolbarsection>
            </Content>

            <Arrow src={ArrowUp} alt="arrow" onClick={scrollToTop}></Arrow>
          </Wrapper>
        </BiggerWrapper>
      </footer>
    );
  }
}

export default Footer;
