import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import { Wrapper, Content, Textbar, Topbar, Pathbar, PlaylistBarText,
     PlaylistYTIcon, Textpath, Arrowright, Ytplayer, Playlistsection,
     PlaylistBarTextTitle, PlaylistBar,PlaylistTitles, TextLink} from './Wideo.styles';
import Arrow from '../../images/arrow_right_blue.png';
import YoutubeEmbed from './YoutubeEmbed';
import YTLogo from '../../images/yt_logo_rgb_dark.png';

import Loading from '../Loading';


const url='https://lorpio-back.ang3r.pl/api/videos';
const API_key='AIzaSyAqhJewbNlLss8HHGfkaX0Pee1trrH5Iow';


class Wideopagee extends Component{
    constructor(props){
        super(props)
        this.state={            
            videos: [],
            videos_id: [],
            current_id: '',
            isLoading: true
        }
    }
    async componentDidMount(){
        const response = await fetch(url);
        const data = await response.json();                

        const videoIds = []
        data.videos.forEach(element => {  
            const s =  element.url.split('/')
            videoIds.push({id:s[3],title: element.title})                          
        }); 
        this.setState({
            videos: data.videos,
            videos_id: videoIds,
            current_id: videoIds[0].id,
            isLoading: false,
        })  
    }

    render(){
        return(
            this.state.isLoading
            ?
                <Loading />
            :
                <Wrapper>
                    <Topbar>
                        <Textbar>FILMY</Textbar>
                    </Topbar>
            
                    <Pathbar>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <TextLink>HOME</TextLink>
                        </Link>
                        <Arrowright src={Arrow} alt='arrow'/>
                        <Textpath>FILMY</Textpath>
                    </Pathbar>

                    <Content>

                        <Ytplayer>
                            <YoutubeEmbed embedId={this.state.current_id}></YoutubeEmbed>
                        </Ytplayer>

                        <Playlistsection>
                            <PlaylistBar>
                                <PlaylistBarTextTitle>LORPIO WebTV NA</PlaylistBarTextTitle>
                                <PlaylistYTIcon src={YTLogo} alt='yt_logo'></PlaylistYTIcon>
                            </PlaylistBar>
                            <PlaylistTitles>
                                {this.state.videos_id.map((item, index) =>  
                                    <PlaylistBarText onClick={() => this.setState({current_id: item.id}) } style={{textDecoration:'none', backgroundColor: index%2 ? 'rgb(35, 35, 35)' : 'transparent', cursor: 'pointer'}}>{item.title}</PlaylistBarText>
                                )}
                            </PlaylistTitles>
                            
                        </Playlistsection>
                    </Content>            
                </Wrapper>
        )
    }
}

export default Wideopagee;