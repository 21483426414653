import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import React, {Component} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Link} from 'react-router-dom';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';


import {
    ItemDescriptionSection,
    ProductNametext, NewsImg, ImgSection, Newestsection, SlideWrapper, Seebutton, WideoDescriptionSection, Ytplayer
} from './Homepage.styles';

import Loading from '../Loading';
import YoutubeEmbed from "./YoutubeEmbed";
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
const url='https://lorpio-back.ang3r.pl/api/videos';


// const url = 'https://lorpio-back.ang3r.pl/api/home_banners';
class VideosSwiper extends Component{
    constructor(props){
        super(props)
        this.state={
            isLoading: true,
            videos: [],

        }
    }

    async componentDidMount(){
        const response = await fetch(url);
        const data = await response.json();

        data.videos = data.videos.slice(0,3)

        const videoIds = []

        this.setState({
            videos: data.videos,
            isLoading: false,
        })
    }

    render(){

        let slides = this.state.videos.map( video =>
            <SwiperSlide style={{display: "flex", padding: "10px 40px"}}>
                    <Newestsection>
                        <SlideWrapper>
                            <WideoDescriptionSection>
                                <ProductNametext style={{"font-weight": '500'}}> FILM</ProductNametext>
                                {/*<ProductNametext style={{"font-size":'1.0em'}}> NOWE PRODUKTY</ProductNametext>*/}
                                <ProductNametext style={{"font-weight": '800', "font-size":'1.3em'}}> {video.title}</ProductNametext>
                            </WideoDescriptionSection>

                            <Ytplayer>
                                {/*<YoutubeEmbed embedId={this.state.current_id.id}></YoutubeEmbed>*/}
                                <YoutubeEmbed embedId={video.url.split('/')[3]}></YoutubeEmbed>
                            </Ytplayer>
                        </SlideWrapper>
                    </Newestsection>
            </SwiperSlide>
        )

        return(
            <Swiper
                navigation
                pagination={{ clickable: true }}
                style={{width: '100%'}}
                autoplay={{ delay: 8000 }}
            >
                {this.state.isLoading
                    ?
                    <SwiperSlide style={{width: '100%', display: "flex", padding: "10px 40px"}}>
                        <div style={{margin: 'auto'}}>
                            <Loading noAdditionalHeight />
                        </div>
                    </SwiperSlide>
                    :
                    slides
                }
            </Swiper>
        )
    }
}

export default VideosSwiper;