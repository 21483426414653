import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import { Wrapper, Content, Textbar, Topbar, Pathbar, Textpath, Arrowright, TextLink} from './Wideo.styles';
import Arrow from '../../images/arrow_right_blue.png';

class Privacypage extends Component{

    render(){
        return(
                <Wrapper>
                    <Topbar>
                        <Textbar>POLITYKA PRYWATNOŚCI</Textbar>
                    </Topbar>

                    <Pathbar>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <TextLink>HOME</TextLink>
                        </Link>
                        <Arrowright src={Arrow} alt='arrow'/>
                        <Textpath>POLITYKA PRYWATNOŚCI</Textpath>
                    </Pathbar>

                    <Content>
                        <div><font face="Arial, serif"><font size="2"><b>Polityka
                            prywatności</b></font></font></div>

                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">Korzystając
                            z Serwisu internetowego </font></font><font color="#0000ff"><u><a href="https://www.lorpio.pl/">https://www.lorpio.pl/</a></u></font><font face="Arial, serif"><font size="2">,
                        Użytkownik oświadcza, że zapoznał się z niniejszą polityką
                        prywatności i wyraża na nią zgodę.</font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">Spółka
                            Lorpio Sp. z o.o., ul.&nbsp;Łochowska 6A, 66-100 Sulechów (zwana
                            dalej &bdquo;LORPIO&quot; lub &bdquo;Administratorem&rdquo;)
                            przykłada szczególną wagę w odniesieniu do respektowania prywatności
                            Użytkowników odwiedzających serwis internetowy administrowany przez
                            LORPIO (dalej zwany: &bdquo;Serwisem&rdquo;). W związku z tym, LORPIO
                            jest odpowiedzialny za przetwarzanie danych osobowych, które są
                            gromadzone i przetwarzane w ramach współpracy z Państwem. Wszystkie
                            dane osobowe są przetwarzane zgodnie z obowiązującymi przepisami o
                            ochronie danych osobowych.</font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">Niniejsza
                            polityka prywatności ma charakter informacyjny, co oznacza że nie
                            jest ona źródłem obowiązków dla Klientów. Polityka prywatności
                            zawiera przede wszystkim zasady dotyczące przetwarzania danych
                            osobowych przez Administratora na portalu internetowym, w tym
                            podstawy, cele i zakres przetwarzania danych osobowych oraz prawa
                            osób, których dane dotyczą, a także informacje w zakresie stosowania
                            plików cookies. Dodatkowo polityka opisuje zasady dotyczące
                            osobistych informacji identyfikacyjnych (dane osobowe), o które
                            możemy Państwa prosić i z których korzystamy za Państwa zgodą.</font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2"><b>1.
                            Informacja dotycząca Administratora</b></font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">Administratorem
                            w rozumieniu art. 4 pkt 7 Rozporządzenia Parlamentu Europejskiego i
                            Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                            fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
                            swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                            (dalej: &bdquo;RODO&rdquo;) w odniesieniu do Państwa danych jest
                            LORPIO Sp. z o.o. ul.&nbsp;Łochowska 6A, 66-100 Sulechów.</font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2"><b>2.
                            Zbieranie i przetwarzanie danych</b></font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">Informacje
                            pochodzące od użytkowników Serwisu (zwanych dalej &bdquo;Użytkownikiem&rdquo;)
                            są gromadzone w sposób opisany poniżej.</font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">W
                            sytuacji kiedy Użytkownik zdecyduje się na skorzystanie z formularza
                            kontaktowego, Administrator gromadzi i przetwarza podane przez
                            Użytkownika dane osobowe: imię i nazwisko, adres e-mail Użytkownika.</font></font></div>

                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2"><b>3.
                            Określenie celów i podstaw przetwarzania</b></font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">Dane
                            osobowe przekazane na formularzu kontaktowym wykorzystywane są
                            wyłącznie w zakresie udzielonej zgody i do konkretnego celu, czyli po
                            to aby udzielić odpowiedzi na przesłane przez Użytkownika zapytanie
                            zawarte w tymże formularzu, znajdującym się na podstronie &bdquo;Kontakt&rdquo;.</font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">Podstawą
                            prawną przetwarzania powyższych danych jest art. 6 ust. 1 lit. b
                            RODO, dający podstawę do przetwarzania danych osobowych, jeżeli są
                            one konieczne do wykonania umowy lub podjęcia czynności zmierzających
                            do zawarcia umowy. W przypadku, gdy zapytanie nie jest związane
                            bezpośrednio z ofertą LORPIO i Użytkownik zaznaczył oświadczenie o
                            wyrażeniu zgody na przetwarzanie danych osobowych znajdujące się pod
                            formularzem, wtedy podstawą prawną takiego przetwarzania jest art. 6
                            ust. 1 lit. a RODO, zezwalający na przetwarzanie danych osobowych na
                            podstawie dobrowolnie udzielonej zgody.</font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">Na
                            stronie internetowej przetwarzane są informacje tekstowe. Podstawą
                            prawną przetwarzania danych w zakresie zastosowania plików cookies
                            jest art. 6 ust. 1 lit. a RODO, który zezwala na przetwarzanie danych
                            osobowych na podstawie dobrowolnie udzielonej zgody.</font></font></div>

                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2"><b>4.
                            Przechowywanie i odbiorcy danych osobowych</b></font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">LORPIO
                            nigdy nie udziela ani nie sprzedaje osobistych informacji
                            identyfikacyjnych innym firmom ani osobom prywatnym. Z zastrzeżeniem
                            postanowień wynikających z RODO i właściwych przepisów, wymiana lub
                            przekazanie danych osobowych może następować wyłącznie w
                            następujących ograniczonych okolicznościach:</font></font></div>
                    <ol type="a">
                        <li key="1"><div align="justify" >
                            <font face="Arial, serif"><font size="2">za
                                zgodą Użytkownika;</font></font></div></li>
                            <li key="2"><div align="justify" >
                                <font face="Arial, serif"><font size="2">dostawcom
                                    oraz innym podmiotom wspierających z LORPIO, w tym dostawcom usług
                                    hostingowych;</font></font></div></li>
                                <li key="3"><div align="justify" >
                                    <font face="Arial, serif"><font size="2">organom
                                        ścigania i organom regulacyjnym lub innym kompetentnym organom
                                        zgodnie z wymogami prawnymi lub dobrą praktyką.</font></font></div></li>
                    </ol>

                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2"><b>5.
                            Czas przechowywania danych osobowych</b></font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">Dane
                            Użytkowników będą przechowywane przez LORPIO do czasu wycofania zgody
                            lub osiągnięcia celu przetwarzania &ndash; w stosunku do danych
                            osobowych przetwarzanych na podstawie zgody udzielonej przez osobę,
                            której dane dotyczą.</font></font></div>

                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2"><b>6.
                            Prawa Użytkowników, których dane dotyczą</b></font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">Użytkownik,
                            która pozostawiła swoje dane osobowe, ma prawo do:</font></font></div>
                    <ol type="a">
                        <li key="4"><div align="justify" >
                            <font face="Arial, serif"><font size="2">Dostępu
                                do danych osobowych i otrzymania ich kopii,</font></font></div></li>
                            <li key="5"><div align="justify" >
                                <font face="Arial, serif"><font size="2">Żądania
                                    sprostowania lub uzupełnienia danych osobowych,</font></font></div></li>
                                <li key="6"><div align="justify" >
                                    <font face="Arial, serif"><font size="2">Prawo
                                        do żądania usunięcia danych osobowych&nbsp;&ndash; osoba, której
                                        dane dotyczą, w przypadku, gdy uzna, że nie ma podstaw, by
                                        Administrator przetwarzał jej dane osobowe, może żądać ich
                                        usunięcia.</font></font></div></li>
                                    <li key="7"><div align="justify" >
                                        <font face="Arial, serif"><font size="2">Ograniczenia
                                            przetwarzania jej danych osobowych&nbsp;&ndash; osoba, której dane
                                            dotyczą może żądać, aby Administrator ograniczył przetwarzanie jej
                                            danych osobowych wyłącznie do ich przechowywania lub wykonywania na
                                            nich czynności uzgodnionych z nią, jeżeli dane są: </font></font>
                                    </div></li>
                    </ol>
                    <ul>
                        <li key="8"><div align="justify" >
                            <font face="Arial, serif"><font size="2">nieprawidłowe
                                lub przetwarzane bez podstawy prawnej, lub;</font></font></div></li>
                            <li key="9"><div align="justify" >
                                <font face="Arial, serif"><font size="2">osoba,
                                    której dane dotyczą nie chce ich usunięcia z uwagi na konieczność
                                    zachowania danych do ustalenia, dochodzenia lub obrony roszczeń lub
                                    na czas rozpoznania sprzeciwu wobec przetwarzania danych osobowych.</font></font></div></li>
                    </ul>

                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2"><b>7.
                            Czy dane osobowe będą przekazywane poza Europejski Obszar
                            Gospodarczy?</b></font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">Na
                            chwilę obecną LORPIO nie przekazuje danych osobowych poza EOG (tj.
                            obszar obejmujący UE, Islandię, Liechtenstein oraz Norwegię).</font></font></div>

                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2"><b>8.
                            Dane kontaktowe</b></font></font></div>
                    <div className="western" align="justify" >
                        <font face="Arial, serif"><font size="2">Wszelkie
                            żądania, prośby, powiadomienia, zapytania dotyczące przetwarzanych
                            przez Administratora Państwa danych osobowych, w tym w zakresie
                            dostępu do ich treści, sposobu aktualizacji lub wycofania zgody na
                            ich przetwarzanie, mogą Państwo kierować pisemnie na adres LORPIO.</font></font></div>
                    </Content>
                </Wrapper>
        )
    }
}

export default Privacypage;