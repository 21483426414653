import styled from "styled-components";

export const Wrapper = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
`;

export const Content =  styled.div`
    background: var(--white);
    min-height: var(--minHeight);
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 50px;
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
        flex-direction: row;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
    @media only screen and (min-width: 768px) and (max-width: 864px),
        only screen and (min-width: 1024px) and (max-width: 1130px) {
        flex-direction: column;
    }
`;

export const Topbar =  styled.div`
    background: var(--blue);
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px 20px;
    width: 90%;
    row-gap: 10px;
    column-gap: 40px;
    flex-wrap: wrap;
    @media only screen and (min-width: 768px) {
        width: 80%;
        flex-direction: row;
        min-height: 80px;
        align-items: center;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
        min-height: 100px;
    }
     
`;
export const Pathbar = styled.div`
    background: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: flex-start;  
    justify-content: flex-start;    
    min-height: 30px;
    flex-wrap: wrap;
    width: 90%;
    padding: 5px 20px 0 20px;
    gap: 5px;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;


export const Textbar = styled.div`
    color: var(--black);
    font-size: 2em;
    @media only screen and (min-width: 1024px) {
        font-size: 2.5em;
    }
`;

export const Textbarwhite = styled.div`
    color: var(--white);
    font-size: 2em;
    @media only screen and (min-width: 1024px) {
        font-size: 2.5em;
    }
`;

export const Text = styled.div`
    color: var(--black);
    font-size: 0.9em;
`;

export const TextLink = styled.div`
    color: var(--black);
    font-size: 0.9em;
    :hover {
        color: var(--blue);
    }
    font-weight:500;
`;

export const DescriptionSection = styled.div`
    font-family: 'Arial', sans-serif !important;
    display: flex;
    flex-direction: column;
    background: var(--white);
    p{
        color: transparent;    
        text-shadow: 0 0 0 black; 
        border-bottom: 1px  solid var(--blue);
        margin: 10px 0 20px 0;
        padding: 0 0 20px 0;
    }
    table{
        color: transparent;    
        text-shadow: 0 0 0 black; 
        border-color: black;
        text-wrap: nowrap;
        p:first-of-type{
            border-bottom: 1px  solid var(--black);
            margin: 0;
            padding: 0;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
        } 
    } 
    @media only screen and (min-width: 768px) {
        min-width: 320px;
    }
`;

export const DescriptionTopBar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: var(--white);
`;

export const ItemName = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--white); 
`;

export const BrandNameText = styled.div`
    font-size: 0.8em;
    color: var(--black);
    margin-bottom: 10px;
    font-weight: bold;
`;

export const ItemNameText = styled.div`
    font-size: 1.0em;
    color: var(--black);
    font-weight: bold;
`;

export const ImgBorder = styled.div`
    border: 2px solid var(--blue);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 40vw;
    height: 40vw;
    max-width: 200px;
    max-height: 200px;
    padding: 5px;
    @media only screen and (min-width: 768px) {
        width: 200px;
        height: 200px;
    }
    @media only screen and (min-width: 1024px) {
        max-width: 300px;
        max-height: 300px;
        width: 300px;
        height: 300px;
    }
`;

export const ImgBorderThumb = styled.div`
    border: 2px solid var(--blue);
    width: 60px;
    height: 60px;
    @media only screen and (min-width: 768px) {
        width: 80px;
        height: 80px;
    }
    @media only screen and (min-width: 1024px) {
        width: 100px;
        height: 100px;
    }
`;

export const NewsImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    color: transparent;
`;

export const ImgSection = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--white);
    max-width: 300px;
    gap: 10px;
    @media only screen and (min-width: 768px) {
        min-width: 300px;
    }
    @media only screen and (min-width: 1024px) {
        max-width: 400px;
        min-width: 400px;
    }
`;

export const DescriptionText = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
    color: var(--black);
    
`;

