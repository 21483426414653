import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import React, {Component} from 'react'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import {Link} from 'react-router-dom';
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';


import {ItemDescriptionSection,
    ProductNametext, NewsImg, ImgSection, Newestsection, SlideWrapper, Seebutton } from './Homepage.styles';

import NewsImage from '../../images/torba1.png';
import { findByLabelText } from '@testing-library/react';
import Loading from '../Loading';
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

const url = 'https://lorpio-back.ang3r.pl/api/home_banners';
class Swiperr extends Component{
  constructor(props){
    super(props)
    this.state={
        isLoading: true,
        banners: [],
        product1: [],  
        product2: [], 
        product3: [],
        img1:[],
        img2:[], 
        img3:[],            
        
    }        
  }

async componentDidMount(){
  const response = await fetch(url);
  const data = await response.json();
  this.setState({ 
    banners: data.home_banners,
    isLoading: false,
  })
  
}

  render(){

    let slides = this.state.banners.map( product =>
        <SwiperSlide style={{display: "flex", padding: "10px 40px"}}>
            <Link to={`/Product/${product.product_id}`} style={{ textDecoration: 'none',  height:'60px' }}>
          <Newestsection>
            <SlideWrapper>
              <ItemDescriptionSection>
                <ProductNametext style={{"font-weight": 'bold', color:'var(--red)'}}> NOWOŚCI</ProductNametext>
                <ProductNametext style={{"font-size":'1.0em'}}> {product.category_title}</ProductNametext>
                <ProductNametext style={{"font-weight": 'bold'}}> {product.title}</ProductNametext>
                <Link to={`/Product/${product.product_id}`} style={{ textDecoration: 'none',  height:'60px' }}>
                    <Seebutton>ZOBACZ</Seebutton>
                </Link>
              </ItemDescriptionSection>
              <ImgSection >
                <NewsImg src={'https://lorpio-back.ang3r.pl'+product.images[0]} alt='product_image'></NewsImg>                
              </ImgSection>
            </SlideWrapper>
          </Newestsection>
            </Link>
        </SwiperSlide>
      )

    return(
        <Swiper
          navigation
          pagination={{ clickable: true }}
          style={{width: '100%'}}
          autoplay={{ delay: 8000 }}
        >
          {this.state.isLoading
          ?
            <SwiperSlide style={{width: '100%', display: "flex", padding: "10px 40px"}}>
              <div style={{margin: 'auto'}}>
                <Loading noAdditionalHeight />
              </div>
            </SwiperSlide>
          :
            slides
          }
        </Swiper>
    )
  }
}

export default Swiperr;