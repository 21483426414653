import React from 'react';

import Header from './Header';

import Topbar from './TopBar';

import Footer from './Footer';

import Newspage from './Newspage';

const News = () => {  
    
    return(
        <div>
            <Header/>
            <Topbar/>
            <Newspage/>
            <Footer/>
           
        </div>
        
    );
};

export default News;