import styled from "styled-components";

export const Wrapper = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
`;

export const Content =  styled.div`
    background: var(--white);
    min-height: var(--minHeight);
    display: flex;    
    flex-direction: column;
    align-items: left;
    align-content: left;  
    justify-content: flex-start;    
    width: 90%;
    padding-top: 20px;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;

export const Topbar =  styled.div`
    background: var(--blue);
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: flex-start;  
    justify-content: flex-start;   
    height: 100px;
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }   
`;

export const Textbar = styled.div`
    color: var(--white);
    margin-left: 20px;
    font-size: 2em;
    @media only screen and (min-width: 1024px) {
        font-size: 2.5em;
    }
    font-weight:800;
`;

export const Pathbar = styled.div`
    background: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: flex-start;  
    justify-content: flex-start;
    height: 30px;
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
    font-weight:500;
`;

export const Textpath = styled.div`
    color: var(--black);
    font-size: 0.9em;
    margin-left: 20px;
`;

export const TextLink = styled.div`
    color: var(--black);
    font-size: 0.9em;
    margin-left: 20px;
    :hover {
        color: var(--blue);
    }
    font-weight:500;
`;

export const Arrowright = styled.img`
    margin-left: 2px;
    margin-right: -17px;
    margin-top: -1.5px;
`;

export const Productline =  styled.div`    
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: left;
    align-content: left;  
    justify-content: flex-start;  
    width: 100%;
    height: 100%;    
`;

export const CategoryText = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--black);
    font-size: 1.5em;
`;

export const CategoryTextLink = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--black);
    font-size: 1.5em;
    :hover {
        font-weight: bold;
    }
`;

export const CategoryBar = styled.div`
    background: var(--white);
    display: flex;
    height: auto;
    width: 100%;
    flex-direction: column;
    padding: 0 20px;
    @media only screen and (min-width: 768px) {
        flex-direction: row;
        gap: 10px;
    }
`;

export const Grid = styled.div`
    background: var(--white);
    height: 100%;
    display: flex;
    flex-wrap:wrap;
    justify-content: flex-start;
    margin: 20px 20px 40px 20px;
    column-gap: 20px;
    justify-items: center;
`;

export const Icon = styled.img`
    width: auto;    
    max-width: 100%; 
    height: auto;    
    max-height: 100%;
    padding: 3.8% 2.3%;    
    margin-bottom: 0px;
`;

export const Border = styled.div`
    border: 2px solid var(--blue);
    height: 160px;
    min-height:160px;
    width: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const Product = styled.div`
    display: flex;
    flex-direction: column;
    height: 230px;
    width: 160px;
`;

export const ProductText = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--black);
    font-size: 0.85em;
    font-weight: bold;
    width: 100%;
    line-height: 1.2rem;
`;
