import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import { Wrapper, Content, Textbar, Topbar, Pathbar, Textpath, Arrowright, TextLink} from './Wideo.styles';
import Arrow from '../../images/arrow_right_blue.png';

class Workpage extends Component{

    render(){
        return(
                <Wrapper>
                    <Topbar>
                        <Textbar>PRACUJ DLA LORPIO</Textbar>
                    </Topbar>

                    <Pathbar>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <TextLink>HOME</TextLink>
                        </Link>
                        <Arrowright src={Arrow} alt='arrow'/>
                        <Textpath>PRACUJ DLA LORPIO</Textpath>
                    </Pathbar>

                    <Content>
                        <div><font face="Arial, serif"><font size="2"><b>PRACUJ DLA LORPIO</b></font></font></div>

                        <div className="western" align="justify">
                            <font face="Arial, serif" size="2">
                                Poszukujemy dwóch PRZEDSTAWICIELI HANDLOWYCH
                                na tereny województw:<br/>
                                1) lubuskie i dolnośląskie<br/>
                                2) opolskie i śląskie<br/>

                            </font>
                        </div>

                        <div className="western" align="justify">
                            <font face="Arial, serif" size="2">

                                DO OBOWIĄZKÓW PRZEDSTAWICIELA NALEŻEĆ BĘDZIE:<br/>
                                • aktywne pozyskiwanie klientów<br/>
                                • obsługa bieżących klientów<br/>

                            </font>
                        </div>

                        <div className="western" align="justify">
                            <font face="Arial, serif" size="2">

                                WYMAGANIA:<br/>
                                • praktyczna znajomość wędkarstwa<br/>
                                • doświadczenie w handlu<br/>
                                • własna działalność gospodarcza, prawo jazdy kat. B<br/>

                            </font>
                        </div>

                        <div className="western" align="justify">
                            <font face="Arial, serif" size="2">
                                ZAPEWNIAMY:<br/>
                                • wyłączność na dystrybucję towarów z oferty LORPIO na terenie wybranych województw<br/>
                                • pracę w firmie o ugruntowanej pozycji rynkowej<br/>
                                • system wynagrodzenia: prowizja, premie<br/>
                                • narzędzia pracy: telefon, laptop<br/>

                            </font>
                        </div>
                        <div className="western" align="justify">
                            <font face="Arial, serif" size="2">

                                Osoby zainteresowane prosimy o przesyłanie CV na adres e-mail: <a href="mailto:praca@lorpio.pl">praca@lorpio.pl</a>
                            </font>
                        </div>
                        <div className="western" align="justify">
                            <font face="Arial, serif" size="2">

                                PROSIMY O UMIESZCZENIE W CV NASTĘPUJĄCEJ KLAUZULI:<br/>
                                „Wyrażam zgodę na przetwarzanie moich danych osobowych przez LORPIO Sp. z o.o. w celu prowadzenia rekrutacji na aplikowane przeze mnie stanowisko”.<br/>
                                Jeżeli chcesz, aby twoja kandydatura była rozpatrywana także w przyszłych rekrutacjach, możesz dołączyć zapis:<br/>
                                „Wyrażam zgodę na przetwarzanie moich danych osobowych przez LORPIO Sp. z o.o. w celu prowadzenia przyszłych rekrutacji”. <br/>                           </font>
                        </div>

                    </Content>
                </Wrapper>
        )
    }
}

export default Workpage;