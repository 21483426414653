
import styled from "styled-components";

export const Wrapper = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
`;

export const Content =  styled.div`
    background: var(--lightGrey);
    min-height: var(--minHeight);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;      
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;

export const Topbar =  styled.div`
    display: grid;
    @media only screen and (min-width: 400px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    width: 100%;
    text-align: center;
`;

export const Sidebar =  styled.div`
    width: 5px ;
    height: 100%;
`;

export const Newest =  styled.div`
    background: var(--red);
    color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 4px;
    font-size: 1.2rem;
    width: 100%;
    height: 80px;
    @media only screen and (min-width: 768px) {
        font-size: 2rem;
        height: 100px;
    }
    :hover {
        color: var(--darkBlue);
    }
`;

export const Products =  styled.div`
    background: var(--blue);
    color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 4px;
    font-size: 1.2rem;
    width: 100%;
    height: 80px;
    @media only screen and (min-width: 768px) {
        font-size: 2rem;
        height: 100px;
    }
    :hover {
        color: var(--lightGrey);
    }
`;

export const Video =  styled.div`
    background: var(--blue);
    color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 4px;
    font-size: 1.2rem;
    width: 100%;
    height: 80px;
    @media only screen and (min-width: 768px) {
        font-size: 2rem;
        height: 100px;
    }
    :hover {
        color: var(--lightGrey);
    }
`;

export const Catalog =  styled.div`
    background: var(--blue);
    color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 4px;
    font-size: 1.2rem;
    width: 100%;
    height: 80px;
    @media only screen and (min-width: 768px) {
        font-size: 2rem;
        height: 100px;
    }
    :hover {
        color: var(--lightGrey);
    }
`;

export const Newestsection =  styled.div`
    background: var(--white);
    display: flex;
    flex-direction: row;
    height: 400px;
    width: 100%;
`;

export const SlideWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 20px;
    padding-bottom: 40px;
    @media only screen and (min-width: 1024px) {
        flex-direction: row;
    }
    
`

export const Videosection =  styled.div`
    background: var(--white);
    display: flex;
    flex-direction: row;
    height: 400px;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
`;

export const Ytplayer = styled.div`        
    overflow: hidden;    
    position: relative;
    align-self: center;
    padding: 20px;
    width: 100%;
    @media only screen and (min-width: 1024px) {
        width: 60%;
    }
`;

export const ItemDescriptionSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
    @media only screen and (min-width: 1024px) {
        align-items: start;
        text-align: start;
    }
`;

export const WideoDescriptionSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 15px;
    @media only screen and (min-width: 1024px) {
        align-items: start;
        text-align: start;
        max-width:40%;
    }
`;

export const ProductNametext = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--black);
    margin: 0px;
    font-size: 1.2em;
    @media only screen and (min-width: 768px) {
        font-size: 1.8em;
    }
    @media only screen and (min-width: 1024px) {
        font-size: 2.0em;
    }
`;

export const NewsImg = styled.img`
    display: flex;
    flex-direction: row;
    object-fit: contain;
    width: 100%;
    height: 80%; 
    color: transparent;
`;
export const ImgSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 400px;
    height: 400px;
    align-self: center;
    padding: 20px 0px;
    @media only screen and (min-width: 1024px) {
        width: 50%;
        padding-left: 0;
        padding: 0px;
    }
    height: 100%;
    
`;

export const Seebutton = styled.div`
    background: var(--blue);
    color: var(--white);
    //text-shadow: 0 0 0 var(--black);
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 140px;
    font-size: 1.2em;
    padding: 20px;
    @media only screen and (min-width: 768px) {
        font-size: 1.8em;
        width: 180px;
    }
    @media only screen and (min-width: 1024px) {
        font-size: 2.0em;
        width: 180px;
    }
`;



