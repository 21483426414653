import styled from "styled-components";

export const Wrapper = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100%;
`;

export const Content =  styled.div`
    background: var(--white);
    min-height: var(--minHeight);
    display: flex;
    align-items: flex-start;
    align-content: flex-start;  
    justify-content: space-between;    
    padding: 20px;
    gap: 20px;
    width: 90%;
    flex-direction: column;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
        flex-direction: row;
    }
`;

export const Topbar =  styled.div`
    background: var(--blue);
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: flex-start;  
    justify-content: flex-start;        
    height: 100px;
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;

export const Textbar = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--white);
    margin-left: 20px;
    font-size: 2em;
    @media only screen and (min-width: 1024px) {
        font-size: 2.5em;
    }
        font-weight:800;
`;

export const Pathbar = styled.div`
    background: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: flex-start;  
    justify-content: flex-start; 
    height: 30px;
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;

export const Textpath = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--black);
    font-size: 0.9em;
    margin-left: 20px;
    font-weight:500;
`;

export const TextLink = styled.div`
    color: var(--black);
    font-size: 0.9em;
    margin-left: 20px;
    :hover {
        font-weight: bold;
    }
`;

export const Arrowright = styled.img`
    margin-left: 2px;
    margin-right: -17px;
    margin-top: -1.5px;
`;

export const Searchlistsection = styled.div`
    background: var(--black);
    display: flex;
    flex-direction: column;
    // width: 37.5%;
    width: 100%;
    height: 400px;
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;

export const SearchBar = styled.div`
    background: var(--blue);
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    min-height: 75px;
`;

export const SearchBarText = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--white);
    font-size: 0.9em;
`;

export const ShopsInCurrentCity = styled.div`
    color: transparent;
    background: var(--black);
    text-shadow: 0 0 0 var(--white);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    overflow: auto;
`;

export const ShopInCurrentCity = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--white);
    font-size: 1.5em;
    :hover {
        font-weight: bold;
    }
`;

export const LoupeIcon = styled.img`
    width: 100px;
    margin: -30px;
`;

export const MapArea = styled.div`
    // width: 55%;
    width: 100%;
    height: 400px;
    background: var(--lightGrey);
    border: 2px solid var(--blue);
`;

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
`

export const NumberInput = styled.input`
    height: 30px;
    width: 60px;
    
    border: 2px solid lightgrey;
    padding: 4px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;

    border-radius: 0px;
    outline: none;

    :hover {
        border-radius: 0px;
        outline: none;
    }
`




