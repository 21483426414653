import React, { Component } from "react";

import FbLogo from "../../images/LOGO_FACEBOOK.svg";
import InstLogo from "../../images/INSTA.svg";
import YtLogo from "../../images/youtube.png";
import TikTokLogo from "../../images/tiktok-logo.svg";
import {
  Wrapper,
  Content,
  LogoFb,
  LogoLn,
  LogoYt,
  LogoTt,
} from "./Header.styles";

const url = "https://lorpio-back.ang3r.pl/api/contact";
class Headerr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fb: "",
      ig: "",
      yt: "",
      tt: "",
    };
  }
  async componentDidMount() {
    const response = await fetch(url);
    const data = await response.json();
    this.setState({
      numbers: data.contact.phoneNumbers,
      fb: data.contact.facebookURL,
      ig: data.contact.linkedinURL,
      yt: data.contact.youtubeURL,
      tt: data.contact.tiktokURL,
    });
  }

  render() {
    return (
      <Wrapper>
        <Content>
          <a href={this.state.fb}>
            <LogoFb src={FbLogo} alt="fb-logo"></LogoFb>
          </a>
          <a href={this.state.ig}>
            <LogoLn src={InstLogo} alt="ln-logo"></LogoLn>
          </a>
          <a href={this.state.yt}>
            <LogoYt src={YtLogo} alt="yt-logo"></LogoYt>
          </a>
          <a href={this.state.tt}>
            <LogoTt src={TikTokLogo} alt="tt-logo"></LogoTt>
          </a>
        </Content>
      </Wrapper>
    );
  }
}

export default Headerr;
