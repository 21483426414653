import React, { useEffect, useState } from 'react';
import { Wrapper, Content, BanerImg } from '../Baner/Baner.styles';

import baner1 from '../../images/banery/baner-top_feeder_2025.jpg'
import baner2 from '../../images/banery/baner-top_kolowrotek_2025.jpg'
import baner3 from '../../images/banery/baner-top_kosz_2025.jpg'
import baner4 from '../../images/banery/baner-top_zylki_2025.jpg'
import baner5 from '../../images/banery/baner-top_parasole_2025.jpg'
import baner6 from '../../images/banery/baner-top_splawiki_2025.jpg'

import {Link} from 'react-router-dom';
const baners = [baner1, baner2, baner3, baner4, baner5, baner6]
const banerLinks = [
    '/Product/303',
    '/Product/297',
    '/Product/348',
    '/Product/336',
    '/Products/96',
    '/Products/30',
]
const delay = 6000 // miliseconds


const Baner = () => {
    const [banerId, setBanerId] = useState(0)    
    useEffect( () => 
        setTimeout(() => 
            setBanerId(banerId+1 < baners.length ? banerId+1 : 0)
        , delay)
    )

    return (
        <Wrapper>
            <Link to={banerLinks[banerId]} style={{ textDecoration: 'none' }}>
                <Content>
                    <BanerImg src={baners[banerId]} alt='baner-image'></BanerImg>
                </Content>
            </Link>
        </Wrapper>
    )
}

export default Baner;