import React,{useCallback, useState, useRef} from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow} from '@react-google-maps/api';
import usePlacesAutocomplete,  {getGeocode, getLatLng} from 'use-places-autocomplete';
import {Combobox, ComboboxInput, ComboboxPopover, ComboboxOption} from '@reach/combobox';
import { SearchContainer, Content, MapArea, SearchBar, SearchBarText, Searchlistsection, ShopsInCurrentCity, ShopInCurrentCity, NumberInput } from './Shop.styles';

import './mapp.css'
const libraries = ['places'];
const mapContainerStyle = {
    width: '100%',
    height: '396px',
};
const center = {
    lat: 51.9194,
    lng: 19.1451
}

export default function Mapp(props){
    const [radius, setRadius] = useState(15)
    const [selectedPlace, setSelectedPlace] = useState(null)

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: "AIzaSyBSpPOkcxdRfhu_6mxA21TnrckzcwfuFPo",
        libraries,
        
    });
    const shops = props;
    
    const markers = shops.props.map((shop, id)=>(
        <Marker key={id} 
            position={{lat: parseFloat(shop.latitude), lng: parseFloat(shop.longitude)}}
            onClick={()=>{
                setSelected(shop)
            }}
        ></Marker>                    
    ))

    const [selected, setSelected] = useState(null);
    const mapRef = useRef();
    const onMapLoad = useCallback((map)=>{
        mapRef.current = map;
    }, [])
    const panTo = useCallback(({lat, lng})=>{
        mapRef.current.panTo({lat, lng});
        mapRef.current.setZoom(10);
    }, [])

    const selectPlace = (place) => {
        setSelectedPlace( place ? { latitude: place.lat, longitude: place.lng} : null)
    }

    const pStyle = {
        color: 'black',
        margin: 0
    }

    if (loadError) return "Error loading maps";
    if(!isLoaded) return "Loading maps...";
    return(
        <Content>
            <MapArea>
                <GoogleMap mapContainerStyle = {mapContainerStyle} zoom={6} center={center} onLoad={onMapLoad}>
                    {markers}
                    
                    {selected ? (<InfoWindow position={{lat: parseFloat(selected.latitude), lng: parseFloat(selected.longitude)}} onCloseClick={()=>{setSelected(null);}}>
                        <div>
                            <h2 style={{ margin: '0 0 4px 0'}}>{selected.name}</h2>
                            <p style={pStyle}>{selected.city}</p>
                            <p style={pStyle}>{selected.postcode} {selected.street}</p>
                            <p style={pStyle}><a href={selected.websiteAddress}>{selected.websiteAddress}</a></p>
                            <p style={pStyle}>{selected.phoneNumber}</p>
                            <p style={pStyle}>{selected.email}</p>
                            <p style={pStyle}>{selected.openingHours}</p>
                            <p style={pStyle}>{selected.comment}</p>
                        </div>
                    </InfoWindow>) : null}
                </GoogleMap>
            </MapArea>
            <Searchlistsection>
                <SearchBar>
                    <SearchBarText>WPROWADŹ MIEJSCOWOŚĆ LUB KOD POCZTOWY ORAZ PODAJ PROMIEŃ</SearchBarText>
                    <SearchContainer>
                        <Search panTo={panTo} selectPlace={selectPlace}/>
                        <NumberInput placeholder="Promień" type="number" value={radius} onChange={(e) => setRadius(+e.target.value)} />
                        <SearchBarText style={{marginBottom: 8}}>KM</SearchBarText>
                    </SearchContainer>
                </SearchBar>
                    <ShopsInCurrentCity>
                        {shops.props.filter( shop => !selectedPlace || getDistance(selectedPlace, shop) < radius).map( (shop, index) => {
                            
                            return(
                                <div key={index} style={{textDecoration:'none', backgroundColor: index%2 ? 'rgb(35, 35, 35)' : 'transparent'}} >
                                    <input type="text" hidden readOnly value={shop.latitude} />
                                    <input type="text" hidden readOnly value={shop.longitude} />
                                    <ShopInCurrentCity style={{fontSize: '1.0em', margin:'10px 4px', cursor: 'pointer'}}
                                        onClick={ e => {
                                            let lat = e.target.parentNode.childNodes[0].value
                                            let lng = e.target.parentNode.childNodes[1].value
                                            let shop = shops.props.find( shop => shop.latitude === lat && shop.longitude === lng)
                                            panTo({ lat: parseFloat(lat), lng: parseFloat(lng)})
                                            setSelected(shop)
                                        }}
                                        >
                                            {shop.name} <br />
                                            {shop.street} <br />
                                            {`${shop.postcode} ${shop.city}`}
                                    </ShopInCurrentCity>
                                </div>
                            )
                        })}
                    </ShopsInCurrentCity>
            </Searchlistsection>
        </Content>
    )
    
}

function Search({panTo, selectPlace}){
    const {ready, value, suggestions: {status, data}, setValue, clearSuggestions} = usePlacesAutocomplete({
        requestOptions: {
            location: {lat: () => 51.9194, lng: () => 19.1451},
            radius: 200 * 1000,
        },
    });
    return (
    <div className="search">
        <Combobox className="combobox" onSelect={async (address) => {
                setValue(address, false);
                clearSuggestions();
                try{
                    
                    const reuslt = await getGeocode({address});
                    const {lat, lng} = await getLatLng(reuslt[0]);
                    panTo({lat, lng});
                    selectPlace({lat, lng})
                } catch(error){
                    console.log(error);
                }
            }}
        >
            <ComboboxInput className="combobox-input" value={value} onChange={(e) => {setValue(e.target.value); e.target.value === '' && selectPlace(null)}} disabled={!ready} placeholder='Wprowadź adres'/>
            <ComboboxPopover className="combobox-popover">
                {status === 'OK' && data.map(({id, description}) => (<ComboboxOption className="combobox-option" key={id} value={description}></ComboboxOption>))}
            </ComboboxPopover>
        </Combobox>
    </div>
    )
}



const rad = (x) => x * Math.PI / 180
const getDistance = (p1, p2) => {
    const R = 6378137; // Earth’s mean radius in meter
    const dLat = rad(p2.latitude - p1.latitude);
    const dLong = rad(p2.longitude - p1.longitude);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.latitude)) * Math.cos(rad(p2.latitude)) *
      Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d / 1000; // returns the distance in km
  };