import styled from "styled-components";

export const Wrapper = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    min-height: var(--minHeight);
`;

export const Content =  styled.div`
    font-family: 'Arial', sans-serif !important;
    background: var(--white);
    min-height: var(--minHeight);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;  
    height: 100%;
    width: 90%;
    gap: 20px;
    padding: 20px;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-template-areas:
            "contact form"
            "search search"
            "map map";
    }
    @media only screen and (min-width: 1400px) {
        grid-template-columns: 2fr 3fr;
    }
`;

export const Topbar =  styled.div`
    background: var(--blue);
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: flex-start;  
    justify-content: flex-start;
    height: 100px;
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;

export const Textbar = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--white);
    margin-left: 20px;
    font-size: 2em;
    @media only screen and (min-width: 1024px) {
        font-size: 2.5em;
    }
    font-weight:800;
`;

export const Pathbar = styled.div`
    background: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: flex-start;  
    justify-content: flex-start;
    height: 30px;
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;

export const Textpath = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--black);
    font-size: 0.9em;
    margin-left: 20px;
`;

export const TextLink = styled.div`
    color: var(--black);
    font-size: 0.9em;
    margin-left: 20px;
    :hover {
        color: var(--blue);
    }
`;

export const Arrowright = styled.img`
    margin-left: 2px;
    margin-right: -17px;
    margin-top: -1.5px;
`;

export const ContactSection = styled.div`
    grid-area: contact;
    display: flex;
    flex-direction: column;
    width: auto;
`;

export const TextContact = styled.div`
font-family: 'Arial', sans-serif;
    color: transparent;
    text-shadow: 0 0 0 var(--black);
    font-size: 0.9em;
`;

export const NewlineTextWrapper = styled.div`
    div[title="normal"] {
        display: none;
    }
    @media only screen and (min-width: 400px) and (max-width: 1023px),
        only screen and (min-width: 1400px) {
        div[title="normal"] {
            display: block;
        }
        div[title="nowrap"] {
            display: none;
        }
    }
`;


export const SocialSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;

export const SocialLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom:20px;
`;

export const LogoFb = styled.img`
    width: 30px; 
    height: 30px; 
    padding-right:8px;  
`;

export const LogoIg = styled.img`
    width: 30px; 
    height: 30px; 
    padding-right:8px;  
        
`;

export const LogoYt = styled.img`
    width: 30px; 
    padding-right:8px;        
`;

export const TextSocial = styled.div`
    color: var(--black);
    text-shadow: 0 0 0 var(--black);
    font-size: 0.9em;
`;

export const SearchSection = styled.div`
    grid-area: search;
    display: flex;
    flex-direction: column;
    margin-top:40px;
`;

export const SearchTextBlue = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--blue);
    font-size: 1.0em;
    margin-bottom: 10px;
`;

export const SearchTextBlack = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--black);
    font-size: 0.9em;

`;

export const MapArea = styled.div`
    grid-area: map;
    width: 100%;
    height: 100%;
    border: 2px solid var(--blue);
    // background: var(--lightGrey);
`;

export const SearchButton = styled.div`
    background: var(--blue);
    height: 35px;
    margin-top: 20px;
    color: var(--white);
    display: flex;    
    align-items: center;
    justify-content: center;
    :hover {
        background: var(--darkBlue);
    }
    width: 150px;
    font-size: 1em;
    @media only screen and (min-width: 300px) {
        width: 200px;
        font-size: 1.2em;
    }
`;

export const FormSection = styled.div`
    grid-area: form;
    background: var(--white);
    height: 100%;
    display: flex;
    flex-direction: column;  
    flex-wrap: wrap;
    width: 100%;
`;
