import styled from "styled-components";

export const BiggerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100%;
    background: var(--black);
    padding: 0 5%;
    @media only screen and (min-width: 768px) {
        padding: 0 10%;
    }
    @media only screen and (min-width: 1024px) {
        padding: 0 15%;
    }
`

export const Wrapper = styled.div`
    background: var(--black);
    width: 100%;
    display: flex;
`;

export const Content =  styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    height: 220px;
    padding-bottom: 20px;
    @media only screen and (min-width: 1210px) {
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        max-width: var(--maxWidth);
        padding-right: 30px;
        padding-bottom: 0;
        height: 60px;
    }
`;

export const LogoLorpio = styled.img`
    display: flex;
    width: 250px;
    height: 50px;
    object-fit: contain;
    align-self: left;
`;

export const NewestText = styled.div`
    color: var(--blue);
    font-size: 1rem;
    font-weight:800;
    :hover {
        color: var(--darkBlue);
    }
`;

export const ProductsText = styled.div`
    color: var(--blue);
    font-size: 1rem;
    font-weight:800;
    :hover {
        color: var(--darkBlue);
    }
`;

export const VideoText = styled.div`
    color: var(--blue);
    font-size: 1rem;
    font-weight:800;
    :hover {
        color: var(--darkBlue);
    }
`;

export const FindText = styled.div`
    color: var(--blue);
    font-size: 1rem;
    font-weight:800;
    :hover {
        color: var(--darkBlue);
    }
`;

export const AdText = styled.div`
    color: var(--blue);
    font-size: 1rem;
    font-weight:800;
    :hover {
        color: var(--darkBlue);
    }
`;

export const ContactText = styled.div`
    color: var(--blue);
    font-size: 1rem;
    font-weight:800;
    :hover {
        color: var(--darkBlue);
    }
`;
