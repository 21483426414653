import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Arrow from '../../images/arrow_right_blue.png';
import Loading from '../Loading'
import {
  Wrapper,
  Topbar,
  Textbar,
  Arrowright,
  Textpath,
  TextLink,
  Pathbar,
  Content
} from './advertisementPage.style'

export default function AdvertisementPage() {
  const [isLoading, setIsLoading] = useState(true)
  const [links] = useState([])

  useEffect( async () => {
    setIsLoading(false)
  }, [])


  useEffect(() => console.log(links))
  return (
    isLoading
    ?
      <Loading />
    :
      <Wrapper>
        <Topbar>
            <Textbar>KATALOG</Textbar>
        </Topbar>
        <Pathbar>
          <Link to="/" style={{ textDecoration: 'none' }}>
              <TextLink>HOME</TextLink>
          </Link>
          <Arrowright src={Arrow} alt='arrow'/>
          <Textpath>KATALOG</Textpath>
        </Pathbar>
          <Content>
              {/*<div>*/}
              {/*    <a href={"https://publuu.com/flip-book/12525/37607/page/1?cover"}*/}
              {/*       style={{width:'auto', height: '350px', position: 'relative', display: 'inline-block'}}*/}
              {/*       target="_blank" rel="noopener"*/}
              {/*       className="show-publuu-viewer"><img*/}
              {/*        src={"https://g2.publuu.com/cover.php?iid=37607&pid=12525&page=1&time=1641804289"}*/}
              {/*        style={{height: '100%'}} /></a>*/}
              {/*    <div style={{padding: '20px', fontWeight: '800', width: '100%', textAlign: 'center'}}>Katalog 2022</div>*/}
              {/*</div>*/}
              {/*<div>*/}
              {/*    <a href={"https://publuu.com/flip-book/12525/631712/page/1?cover"}*/}
              {/*       style={{width:'auto', height: '350px', position: 'relative', display: 'inline-block'}}*/}
              {/*       target="_blank" rel="noopener"*/}
              {/*       className="show-publuu-viewer"><img*/}
              {/*        src={"https://d1uiew9hysv4w7.cloudfront.net/12525/631712/2/txt/631712_1_1200.webp"}*/}
              {/*        style={{height: '100%'}} /></a>*/}
              {/*    <div style={{padding: '20px', fontWeight: '800', width: '100%', textAlign: 'center'}}>Katalog 2024</div>*/}
              {/*</div>*/}
              <div>
                  <a href={"https://publuu.com/flip-book/12525/1488367/page/1?cover"}
                     style={{width: 'auto', height: '350px', position: 'relative', display: 'inline-block'}}
                     target="_blank" rel="noopener"
                     className="show-publuu-viewer"><img
                      src={"https://d3h1nb5bjihg9w.cloudfront.net/12525/1488367/txt/1488367_1_1200.webp"}
                      style={{height: '100%'}}/></a>
                  <div style={{padding: '20px', fontWeight: '800', width: '100%', textAlign: 'center'}}>Katalog 2025
                  </div>
              </div>
          </Content>
      </Wrapper>
  )
}