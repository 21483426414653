import React from 'react';
import Header from './Header';
import Topbar from './TopBar';
import Footer from './Footer';
import AdvertisementPage from './AdvertisementPage';

export default function Catalog() {
  
  return (
    <>
      <Header/>
      <Topbar/>
      <AdvertisementPage />
      <Footer/> 
    </>      
  )
}
