import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
  
  <div style={{
    position: "relative",
    width: "100%",
    paddingTop: "56.25%"
  }}>
    <div className="video-responsive" style={{
      position: "absolute",
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
      width: "100%"
    }}>
      <iframe
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;