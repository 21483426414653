import styled from "styled-components";

export const Wrapper = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;

    max-width: var(--maxWidth);
`;

export const Content =  styled.div`
    background: var(--white);
    min-height: var(--minHeight);
    display: flex;
    flex-wrap: wrap;
    flex:1;
    flex-direction: row;
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;

export const Grid = styled.div`
    background: var(--white);
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 1rem;  
    margin: 20px 20px 20px 20px;
    justify-content: center;
    /* display:flex;
    flex-direction: row;
    flex-wrap: wrap; */
`;

export const Productline =  styled.div` 
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;  
    justify-content: space-evenly;  
    width: 100%;
    height: 100%;  
`;

export const Topbar =  styled.div`
    background: var(--blue);
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: flex-start;  
    justify-content: flex-start;  
    height: 100px;
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }   
`;
export const Pathbar = styled.div`
    background: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: flex-start;  
    justify-content: flex-start;
    height: 30px;
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;

export const Textbar = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--white);
    margin-left: 20px;
    font-size: 2em;
    @media only screen and (min-width: 1024px) {
        font-size: 2.5em;
    }
    font-weight: 800;
`;

export const Text = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--black);
    font-size: 0.9em;
    margin-left: 20px;
`;

export const TextLink = styled.div`
    color: var(--black);
    font-size: 0.9em;
    margin-left: 20px;
    :hover {
        color: var(--blue);
    }
    font-weight:500;
`;

export const Arrowright = styled.img`
    margin-left: 2px;
    margin-right: -17px;
    margin-top: -1.5px;
`;

export const Square = styled.div`
    background: var(--blue);
    height: 150px;
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: center;  
    justify-content: center; 
    text-align: center;
    :hover {
        background: var(--darkBlue);
    }
`;

export const Textsquare = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--white);
    font-size: 1em;
    font-weight:800;
    padding:10px;
`;

