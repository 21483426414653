import styled from 'styled-components';

// export const Wrapper = styled.div`
//         background: linear-gradient(
//             to bottom, rgba(0,0,0,0)
//             41%, rgba(0,0,0,0.65)
//             100%
//         ),
//         url(${({ image }) => image}), var(--dark);
//         background-size: 100%, cover;
// `;

export const Wrapper = styled.div`
    background-size: 100%, cover;
    background-position: center;
    display: flex;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: var(--maxWidth);
    
`;

export const BanerImg = styled.img`
    flex: 1;
    width: 100%;
    height: 100%;
    resizeMode: cover;
`;