import React, { useState } from 'react';
import './Form.css'
import {Link} from 'react-router-dom';

const Form = () => {
    
    const [values, setValues] = useState({
        Name: "",
        Email: "",
        Message: "",
    });

    const [submitted, setSubmitted] = useState(false);
    const [valid, setValid] = useState(false);

    const handleNameInputChange = (event) => {
        setValues({...values, Name: event.target.value})
    }
    const handleEmailInputChange = (event) => {
        setValues({...values, Email: event.target.value})
    }
    const handleMessageInputChange = (event) => {
        setValues({...values, Message: event.target.value})
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if(values.Name && values.Message && values.Email){
            setValid(true);
        }
        setSubmitted(true);
    }
    return (
    <div class='container'>
        <form class='email-form' onSubmit={handleSubmit}>
            
            <label class='label label-title'>
                <label class='label-text label-hidden' hidden></label>
                <div class="form-bar">SKONTAKTUJ SIĘ Z NAMI</div>
            </label>

            <label class='label'>
                <label class='label-text'>
                    Imię i nazwisko
                </label>
                <input 
                disabled={submitted}
                onChange={handleNameInputChange} value = {values.Name} 
                class='form-field'  name='Name'
                />
            </label>
            
            {submitted && !values.Name ? <span class='error'>Podaj swoję imię i nazwisko</span>:null}
            
            <label class='label'>
                <label class='label-text'>
                    Adres e-mail
                </label>
                <input 
                disabled={submitted}
                onChange={handleEmailInputChange} value = {values.Email} 
                class='form-field'  name='Email'
                />
            </label>
            
            {submitted && !values.Email ? <span class='error'>Podaj swój adres e-mail</span>:null}
            
            <label class='label-message'>
                <label class='label-message-text'>
                    Treść wiadomości
                </label>
                {/* <input  type='text'
                disabled={submitted}
                onChange={handleMessageInputChange} value = {values.Message} 
                class='message-field'  name='Message'
                ></input> */}
                <textarea 
                disabled={submitted} 
                onChange={handleMessageInputChange} value = {values.Message} 
                class='message-field'  name='Message'></textarea>
            </label>
            {submitted && valid ? <div className='succes-message'>Udało się wysłać wiadomość.</div>:null}

            {submitted && !values.Message ? <span class='error'>Wpisz swoją wiadomość</span>:null}

            <label style={{fontSize: '12px'}}>
                <input type="checkbox"
                       required={'Required'}
                />

                Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 rok o ochronie danych osobowych osób fizycznych w związku z wysłaniem zapytania. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Oświadczam, że zostałem/am poinformowany /a, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. Dane zapisane w formularzu kontaktowym będą przetwarzane w celu udzielenia odpowiedzi na przesłane zapytanie. Więcej informacji można przeczytać na stronie <Link to={'/Prywatnosc'}>polityka prywatności.</Link>
            </label>

            <button class='button' type='submit'>Wyślij</button>
        </form>
    </div>   
    );
};

export default Form;