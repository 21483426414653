import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import { Wrapper, Content, Textbar, Topbar, Pathbar, Textpath, Arrowright, TextLink} from './Wideo.styles';
import Arrow from '../../images/arrow_right_blue.png';

class ContestRegulationspage extends Component{

    render(){
        return(
                <Wrapper>
                    <Topbar>
                        <Textbar>REGULAMIN KONKURSU</Textbar>
                    </Topbar>

                    <Pathbar>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <TextLink>HOME</TextLink>
                        </Link>
                        <Arrowright src={Arrow} alt='arrow'/>
                        <Textpath>REGULAMIN KONKURSU</Textpath>
                    </Pathbar>

                    <Content>
                      <>
                        <font face="Arial, sans-serif">
                          <b>
                            REGULAMIN KONKURSU NA FACEBOOKU
                            <br />Z DNIA 14.07.2023 r.
                          </b>
                        </font>
                        <font face="Arial, sans-serif">
                          <b>§ 1. POSTANOWIENIA OGÓLNE</b>
                        </font>
                        <span style={{ fontVariant: "normal" }}>
    <font color="#000000">
      <font face="Arial, sans-serif">
        1. Organizatorem <b>konkursu na Facebooku (zwanego dalej ”Konkursem”)</b>, jest LORPIO sp. z o.o. <br />
                z siedzibą na ul. Łochowskiej 6A, 66-100 Sulechów.
                <br />
                (zwany dalej „Organizatorem”).
      </font>
    </font>
  </span>


                        <font face="Arial, sans-serif">2. Fundatorem nagrody jest Organizator.</font>
                        <font face="Arial, sans-serif">
                          3. Administratorem danych osobowych udostępnianych przez Uczestników
                          Konkursu jest Organizator.
                        </font>
                        <font face="Arial, sans-serif">
                          4. Podanie danych osobowych ma charakter dowolny, lecz niezbędny do
                          przystąpienia przez Uczestnika do Konkursu. Osobom udostępniającym dane
                          przysługuje prawo dostępu do tych danych, ich zmian bądź usunięcia.
                        </font>
                        <font face="Arial, sans-serif">
                          5. Niniejszy regulamin określa warunki Konkursu.
                        </font>
                        <font face="Arial, sans-serif">
                          6. Konkurs nie jest stworzony, administrowany, wspierany ani sponsorowany
                          przez Facebooka. Facebook jest znakiem towarowym zastrzeżonym przez
                          Facebook, Inc.
                        </font>
      <font face="Arial, sans-serif">
                7. Konkurs jest prowadzony na stronie{" "}<a href="https://www.facebook.com/LorpioPL">https://www.facebook.com/LorpioPL</a> (zwanej dalej “Fanpage”).
      </font>


                        <font face="Arial, sans-serif">
                          8. Nadzór nad prawidłowością i przebiegiem Konkursu, tj. udzielaniem
                          informacji na temat Konkursu oraz rozpatrywaniem reklamacji sprawuje
                          Organizator.
                        </font>
                        <font face="Arial, sans-serif">
                          <b>§ 2. UCZESTNICY KONKURSU</b>
                        </font>
                        <font face="Arial, sans-serif">
                          1. Uczestnikami Konkursu mogą być wyłącznie osoby fizyczne, konsumenci w
                          rozumieniu art. 221 kodeksu cywilnego , posiadające pełną zdolności do
                          czynności prawnych, będące użytkownikami i posiadający aktywne konto w
                          serwisie Facebook.com; które zaakceptowały niniejszy Regulamin (dalej:
                          „Uczestnik”).
                        </font>
                        <font face="Arial, sans-serif">2. Uczestnik oświadcza, że:</font>

                        <font color="#000000">
                          <font face="Arial, sans-serif">
                            {" "}
                            a) jest osobą fizyczną, posiadającą pełną zdolność do czynności prawnych;
                          </font>
                        </font>


                        <font color="#000000">
                          <font face="Arial, sans-serif">
                            {" "}
                            b) zapoznał się z treścią niniejszego Regulaminu i w sposób dobrowolny
                            przystępuje do Konkursu;
                          </font>
                        </font>


                        <font color="#000000">
                          <font face="Arial, sans-serif">
                            {" "}
                            c) wyraża zgodę i akceptuje warunki Regulaminu, w tym zapoznał się z treścią
                            dotyczącą procedury odbioru nagrody i ją w pełni akceptuje;
                          </font>
                        </font>


                        <font color="#000000">
                          <font face="Arial, sans-serif">
                            {" "}
                            d) zobowiązuje się do przestrzegania postanowień Regulaminu, w tym również
                            regulaminu Facebooka;
                          </font>
                        </font>


                        <font color="#000000">
                          <font face="Arial, sans-serif">
                            {" "}
                            e) wyraził zgodę na przetwarzanie danych osobowych dla celów związanych z
                            uczestnictwem w Konkursie;
                          </font>
                        </font>

                        <font color="#000000">
                          <font face="Arial, sans-serif">
                            {" "}
                            f) jest zarejestrowanym Użytkownikiem portalu społecznościowego Facebook;
                          </font>
                        </font>
                        <font face="Arial, sans-serif">
                          3. W Konkursie nie mogą uczestniczyć pracownicy i współpracownicy
                          Organizatora.
                        </font>
                        <font face="Arial, sans-serif">
                          <b>§ 3. NAGRODA</b>
                        </font>
                        <font face="Arial, sans-serif">
                          1. W Konkursie przewidziano nagrodę dla 1 osoby wyłonionej w sposób wskazany
                          w §6.
                        </font>
                        <font face="Arial, sans-serif">2. Nagrodą jest:</font>


                        <font color="#000000">
                          <font face="Arial, sans-serif">a) Zestaw Lorpio EVA okrągły do pelletu</font>
                        </font>


                        <font color="#000000">
                          <font face="Arial, sans-serif">
                            {" "}
                            b) Pellet Lorpio Green Betaine 2,0 mm 700 g
                          </font>
                        </font>


                        <font color="#000000">
                          <font face="Arial, sans-serif">
                            {" "}
                            c) Dodatek Lorpio Liquid Booster Bream 500 ml
                          </font>
                        </font>
      <font face="Arial, sans-serif">
                3. Informacja o Nagrodzie będzie zawarta w treści ogłoszenia o
                Konkursie opublikowanego na portalu Facebook, na profilu{" "}                            <a href="https://www.facebook.com/LorpioPL">
          https://www.facebook.com/LorpioPL
      </a>
        </font>
                        <font face="Arial, sans-serif">
                          4. Laureatom nie przysługuje prawo wymiany Nagrody na gotówkę ani nagrodę
                          innego rodzaju.
                        </font>
                        <font face="Arial, sans-serif">
                          5. Zwycięzcy mogą zrzec się Nagrody, ale w zamian nie przysługuje im
                          ekwiwalent pieniężny ani jakakolwiek inna nagroda.
                        </font>
                        <font face="Arial, sans-serif">
                          <b>§ 4. MIEJSCE, CZAS I ZASADY KONKURSU</b>
                        </font>
      <font face="Arial, sans-serif">
                1. Konkurs jest dostępny w formie ogłoszenia konkursowego
                (dalej: „post konkursowy“) na portalu społecznościowym Facebook
                na profilu Organizatora pod adresem{" "}                            <a href="https://www.facebook.com/LorpioPL">

                      https://www.facebook.com/LorpioPL

                            </a>
      </font>
                        <font face="Arial, sans-serif">
                          2. Konkurs trwa od dnia 14.07.2023 r. godz. 10:00 do 21.07.2023 r. godz.
                          10:00
                        </font>
                        <font face="Arial, sans-serif">
                          <b>§ 5. ZASADY UCZESTNICTWA W KONKURSIE</b>
                        </font>
                        <font face="Arial, sans-serif">
                          1. Zadaniem Uczestnika Konkursu jest napisanie komentarza pod postem
                          konkursowym wyjaśniającego za co lubi produkty Lorpio.
                        </font>
                        <span style={{ fontVariant: "normal" }}>
    <font color="#000000">
      <font face="Arial, sans-serif">
        <font style={{ fontSize: "10pt" }} size={2}>
          <span style={{ letterSpacing: "normal" }}>
            <span style={{ fontStyle: "normal" }}>
              <span style={{ fontWeight: "normal" }}>
                2. Informacje o konkursie będą dostępne na Facebooku pod adresem{" "}
              </span>
            </span>
          </span>
        </font>
      </font>
    </font>
  </span>
                        <font color="#000080">
                          <u>
                            <a href="https://www.facebook.com/LorpioPL">
        <span style={{ fontVariant: "normal" }}>
          <font color="#000000">
            <font face="Arial, sans-serif">

                      https://www.facebook.com/LorpioPL

            </font>
          </font>
        </span>
                            </a>
                          </u>
                        </font>
                        <font face="Arial, sans-serif">
                          <b>§ 6. WARUNKI UCZESTNICTWA W KONKURSIE I ODBIORU NAGRÓD</b>
                        </font>
                        <font face="Arial, sans-serif">
                          1. Dostęp do Konkursu jest bezpłatny i wymaga rejestracji na portalu
                          społecznościowym Facebook.
                        </font>
                        <font face="Arial, sans-serif">
                          2. Warunkiem uczestnictwa w Konkursie jest zaakceptowanie Regulaminu oraz
                          poprawne wykonanie wszystkich zadań opisanych w § 5. Ust. 1 Regulaminu.
                        </font>
                        <font face="Arial, sans-serif">
                          3. O przyznaniu nagrody decyduje Organizator w drodze analizy poprawności
                          oraz atrakcyjności wykonania zadań opisanych w § 5. Ust. 1 Regulaminu przez
                          Uczestników Konkursu.
                        </font>
                        <font face="Arial, sans-serif">
                          4. Spośród nadesłanych zgłoszeń Organizator wyłoni jednego zwycięzcę.
                        </font>
                        <font face="Arial, sans-serif">
                          5. Zwycięzca Konkursu zostanie powiadomiony o wygranej i warunkach odbioru
                          Nagrody za pośrednictwem wiadomości prywatnej, wysłanej na Facebooku w ciągu
                          2 dni roboczych od momentu zakończenia konkursu.
                        </font>
                        <span style={{ fontVariant: "normal" }}>
    <font color="#000000">
      <font face="Arial, sans-serif">
        <font style={{ fontSize: "10pt" }} size={2}>
          <span style={{ letterSpacing: "normal" }}>
            <span style={{ fontStyle: "normal" }}>
              <span style={{ fontWeight: "normal" }}>
                6. Publiczna informacja o wygranej zostanie również umieszczona
                w komentarzu do posta konkursowego na stronie{" "}
              </span>
            </span>
          </span>
        </font>
      </font>
    </font>
  </span>
                        <font color="#000080">
                          <u>
                            <a href="https://www.facebook.com/LorpioPL">
        <span style={{ fontVariant: "normal" }}>
          <font color="#000000">
            <font face="Arial, sans-serif">

                      https://www.facebook.com/LorpioPL

            </font>
          </font>
        </span>
                            </a>
                          </u>
                        </font>
                        <font face="Arial, sans-serif">
                          7. Warunkiem odebrania przez wyróżnionego Uczestnika nagrody jest przesłanie
                          w ciągu 24 godzin od ogłoszenia wyników Konkursu na Fanpage`u wiadomości
                          prywatnej z następującymi danymi:
                        </font>
                        <font color="#000000">
                          <font face="Arial, sans-serif">a) imię i nazwisko</font>
                        </font>
                        <font color="#000000">
                          <font face="Arial, sans-serif">b) adres korespondencyjny</font>
                        </font>
                        <font color="#000000">
                          <font face="Arial, sans-serif">c) numer telefonu</font>
                        </font>
                        <font color="#000000">
                          <font face="Arial, sans-serif">d) adres mailowy</font>
                        </font>
                        <font face="Arial, sans-serif">
                          8. Brak wysłania wiadomości, o której mowa w pkt. 7 lub przekroczenie
                          dopuszczalnego czasu odpowiedzi lub wysłanie nieprawidłowych danych powoduje
                          utratę przez uczestnika prawa do nagrody.
                        </font>
                        <font face="Arial, sans-serif">
                          9. Przyznana w Konkursie nagroda zostanie wysłana uczestnikowi do 2 dni
                          roboczych od dnia otrzymania przez Organizatora informacji, o której mowa w
                          ust. 7.
                        </font>
                        <font face="Arial, sans-serif">
                          10. Nagrody zostaną wysłane na koszt Organizatora przesyłką kurierską na
                          adres wskazany przez Uczestnika.
                        </font>
                        <font face="Arial, sans-serif">
                          11. Na podstawie art. 30 ust. 1 pkt 2 ustawy z dnia 26 lipca 1991 r. o
                          podatku dochodowym od osób fizycznych (Dz.U. z 2022.2647) łączna wartość
                          przekazanych zwycięzcy nagród rzeczowych określonych w § 3 niniejszego
                          Regulaminu podlega opodatkowaniu zryczałtowanym podatkiem dochodowym od osób
                          fizycznych w wysokości 10% wartości Nagrody. Organizator, jako płatnik
                          zryczałtowanego podatku dochodowego od osób fizycznych, przed wydaniem
                          zwycięzcy Nagród obliczy, pobierze i odprowadzi do właściwego Urzędu
                          Skarbowego zryczałtowany podatek dochodowy należny z tytułu wygranej. W tym
                          celu do nagród rzeczowych określonych w § 3 niniejszego Regulaminu zostanie
                          dodana nagroda pieniężna w wysokości odpowiadającej zryczałtowanemu
                          podatkowi dochodowemu od osób fizycznych z tytułu wygranej w Konkursie,
                          liczonemu od ubruttowionej o należny podatek dochodowy łącznej wartości
                          nagród rzeczowych i pieniężnych (tj. w kwocie stanowiącej 11,11% wartości
                          nagród rzeczowych). Zwycięzca zgadza się, że kwota nagrody pieniężnej nie
                          podlega wypłacie na jego rzecz, lecz przeznaczona jest na zapłatę podatku
                          należnego z tytułu wygranej w Konkursie, obciążającego zwycięzcę. Zwycięzca
                          Konkursu jest zobowiązany przekazać Organizatorowi wszelkie dane, niezbędne
                          do wypełnienia obowiązków płatnika.{" "}
                        </font>
                        <font face="Arial, sans-serif">
                          <b>§ 7. ZAKRES ODPOWIEDZIALNOŚCI ORGANIZATORA</b>
                        </font>
                        <font face="Arial, sans-serif">
                          1. Organizator nie ponosi odpowiedzialności za rzetelność i prawdziwość
                          danych Uczestników Konkursu, w tym za brak możliwości przekazania nagród, z
                          przyczyny leżących po stronie Uczestnika, w szczególności, jeśli ten nie
                          podał prawdziwego adresu do korespondencji lub podane dane są niepełne lub
                          nieaktualne.
                        </font>
                        <font face="Arial, sans-serif">
                          2. Organizator oświadcza, że nie prowadzi kontroli, ani monitoringu treści
                          umieszczanych przez Uczestników w zakresie rzetelności i prawdziwości, z
                          zastrzeżeniem działań związanych z usunięciem naruszeń Regulaminu lub
                          przepisów powszechnie obowiązujących.
                        </font>
                        <font face="Arial, sans-serif">
                          3. Organizator zastrzega sobie prawo do wykluczenia z udziału w Konkursie
                          Uczestników, których działania są sprzeczne z prawem lub Regulaminem oraz
                          regulaminem Facebooka, w szczególności uczestników, którzy:
                        </font>
                        <font face="Arial, sans-serif">
                          a) zamieszczają treści niezgodne z obowiązującym prawem lub Regulaminem
                          dostępnym na portalu Facebook (w szczególności zawierające treści obraźliwe,
                          zarówno w warstwie tekstowej, jak i graficznej);
                        </font>
                        <font face="Arial, sans-serif">
                          b) podejmują działania z wykorzystaniem konta/profilu utworzonego niezgodnie
                          z zasadami Facebooka;
                        </font>
                        <font face="Arial, sans-serif">
                          c) podejmują działania z wykorzystaniem niezgodnych z zasadami Facebooka
                          kont/profili osób trzecich;
                        </font>
                        <font face="Arial, sans-serif">
                          d) ingerują w mechanizm działania Konkursu;
                        </font>
                        <font face="Arial, sans-serif">
                          e) tworzą fikcyjne konta/profile w serwisie Facebook
                        </font>
                        <font face="Arial, sans-serif">
                          4. Organizator nie ponosi odpowiedzialności za jakiekolwiek zakłócenia w
                          działaniu łącz teleinformatycznych, serwerów, interfejsów, przeglądarek oraz
                          platformy Facebook.
                        </font>
                        <font face="Arial, sans-serif">
                          5. Organizator nie ponosi odpowiedzialności za czasowe lub stałe
                          zablokowanie strony lub aplikacji ze strony Facebooka.
                        </font>
                        <font face="Arial, sans-serif">
                          <b>§ 8. PRZETWARZANIE DANYCH OSOBOWYCH</b>
                        </font>
                        <font face="Arial, sans-serif">
                          1. Dane osobowe Uczestników Konkursu będą przetwarzane przez Organizatora
                          wyłącznie w celu dokonania czynności niezbędnych do prawidłowego
                          przeprowadzenia Konkursu.
                        </font>
                        <font face="Arial, sans-serif">
                          2. Dane osobowe Uczestników Konkursu będą przechowywane przez Organizatora
                          tylko przez okres niezbędny do przeprowadzenia Konkursu i wydania nagród
                          wyróżnionym Uczestnikom.
                        </font>
                        <font face="Arial, sans-serif">
                          3. Uczestnicy mają prawo wglądu do przetwarzanych danych i ich poprawiania
                          oraz usuwania. Dane są podawane na zasadach dobrowolności, przy czym w
                          zakresie uczestnictwa w Konkursie wymagana jest rejestracja na portalu
                          społecznościowym Facebook.
                        </font>
                        <font face="Arial, sans-serif">
                          4. W momencie usunięcia danych Użytkownik traci możliwość Uczestnictwa w
                          Konkursie.
                        </font>
                        <font face="Arial, sans-serif">
                          <b>§ 9. PRAWA AUTORSKIE</b>
                        </font>
                        <font face="Arial, sans-serif">
                          1. Wszelkie prawa własności intelektualnej do Konkursu przysługują
                          Organizatorowi. Uczestnictwo w Konkursie nie skutkuje w żadnym zakresie
                          nabyciem przez Uczestników jakichkolwiek praw własności intelektualnej.
                          Zabronione jest naruszanie w jakikolwiek sposób praw własności
                          intelektualnej w Konkursie, w szczególności:
                        </font>
                        <font face="Arial, sans-serif">
                          a) kopiowanie, modyfikowanie oraz transmitowanie elektronicznie lub
                          rozpowszechnianie w inny sposób mechanizmu Konkursu lub jego części, a także
                          poszczególnych utworów i baz danych, bez wyraźnej pisemnej zgody
                          Administratora;
                        </font>
                        <font face="Arial, sans-serif">
                          b) korzystanie z Konkursu w sposób niezgodny z Regulaminem lub powszechnie
                          obowiązującymi przepisami.
                        </font>
                        <font face="Arial, sans-serif">
                          <b>§ 10. REKLAMACJE I ZGŁOSZENIA NARUSZEŃ</b>
                        </font>
                        <font face="Arial, sans-serif">
                          1. Wszelkie reklamacje dotyczące sposobu przeprowadzania Konkursu,
                          Uczestnicy winni zgłaszać na piśmie w czasie trwania Konkursu, jednak nie
                          później niż w terminie 14 (czternastu) dni od dnia wydania Nagród.
                        </font>
                        <font face="Arial, sans-serif">
                          2. Reklamacja zgłoszona po wyznaczonym terminie nie wywołuje skutków
                          prawnych.
                        </font>
                        <font face="Arial, sans-serif">
                          3. Pisemna reklamacja powinna zawierać imię, nazwisko, dokładny adres
                          Uczestnika oraz dokładny opis i uzasadnienie reklamacji.
                        </font>
                        <font face="Arial, sans-serif">
                          4. Reklamacja powinna być przesłana listem poleconym na adres
                          Organizatora&nbsp;
                        </font>
                        <font face="Arial, sans-serif">
                          5. Reklamacje rozpatrywane będą pisemnie w terminie 30 dni.
                        </font>
                        <font face="Arial, sans-serif">
                          <b>§ 11. POSTANOWIENIA KOŃCOWE</b>
                        </font>
                        <font face="Arial, sans-serif">
                          1. Regulamin wchodzi w życie z dniem 14.07.2023 r.
                        </font>
                        <font face="Arial, sans-serif">
                          2. Organizator zastrzega sobie prawo do zmiany Regulaminu w przypadku
                          zaistnienia ważnych przyczyn organizacyjnych, ekonomicznych i prawnych. Za
                          ważne przyczyny uznaje się:
                        </font>
                        <font face="Arial, sans-serif">
                          a) zmianę przepisów prawa regulujących zasady i organizację Konkursu,
                          wpływającą na wzajemne prawa i obowiązki lub zmiana interpretacji powyższych
                          przepisów prawa wskutek orzeczeń sądów, decyzji, rekomendacji lub zaleceń
                          właściwych w danym zakresie urzędów lub organów;
                        </font>
                        <font face="Arial, sans-serif">
                          b) zmianę sposobu przeprowadzania Konkursu spowodowaną wyłącznie względami
                          technicznymi lub technologicznymi.
                        </font>
                        <font face="Arial, sans-serif">
                          3. W kwestiach nieuregulowanych niniejszym Regulaminem stosuje się przepisy
                          Kodeksu cywilnego i inne przepisy prawa.
                        </font>
                        <font face="Arial, sans-serif">
                          4. Spory odnoszące się i wynikające z Konkursu będą rozwiązywane przez sąd
                          powszechny właściwy miejscowo dla siedziby Organizatora.
                        </font>
                      </>

                    </Content>
                </Wrapper>
        )
    }
}

export default ContestRegulationspage;