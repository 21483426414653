import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { Wrapper, Content, Topbar,  Newest, Products, Video, 
    Catalog, Videosection, Newestsection, Ytplayer, WideoDescriptionSection,
    ProductNametext,
    SlideWrapper,   } from './Homepage.styles';

import YoutubeEmbed from "./YoutubeEmbed";
import Baner from './Swiper';
import Loading from '../Loading';
import VideosSwiper from "./VideosSwiper";

class Homepage extends Component{
    constructor(props){
        super(props)
        this.state={ 
            isLoading: true,           
            videos: [],
            videos_id: [],
            current_id:[]
        }
    }

    async componentDidMount(){
        // const response = await fetch(url);
        // const data = await response.json();
        //
        // const videoIds = []
        // data.videos.forEach(element => {
        //     const s =  element.url.split('/');
        //     videoIds.push({id:s[3],title: element.title})
        // });

        this.setState({
            // videos: data.videos,
            // videos_id: videoIds,
            // current_id: videoIds[1],
            isLoading: false
        })
        
    }
    render(){
        return(
            <>
                {this.state.isLoading && <Loading />}            
                <Wrapper style={this.state.isLoading ? {height: 0, opacity: 0} : {}}>
                    <Content>
                        <Topbar>
                            
                            <Link to='/News' style={{ fontWeight: '800', textDecoration: 'none', width:'100%',
                            display: 'flex', 'flex-direction': 'row',
                            'justify-content': 'space-evenly'}}>
                                <Newest>NOWOŚCI</Newest>
                            </Link>
            
                            <Link to='/Products' style={{ fontWeight: '800', textDecoration: 'none', width:'100%' }}>
                                <Products>PRODUKTY</Products>
                            </Link>
            
                            <Link to='/Wideo' style={{ fontWeight: '800', textDecoration: 'none', width:'100%' }}>
                                <Video>FILMY</Video>
                            </Link>
            
                            <Link to='/Katalog' target="_blank" rel="noopener noreferrer" style={{ fontWeight: '800', textDecoration: 'none', width:'100%',
                            'margin-right': '20px' }}>
                                <Catalog>KATALOG</Catalog>
                            </Link>
            
                            
                            
                        </Topbar>
                        <Newestsection>
                            <Baner></Baner>
                        </Newestsection>
                        <Videosection>
                            <VideosSwiper></VideosSwiper>
                        </Videosection>
                    </Content>
                </Wrapper>
            </>
        )
    }

}
export default Homepage;