import styled from "styled-components";

export const Wrapper = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
`;

export const Content =  styled.div`
    background: var(--white);
    min-height: var(--minHeight);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    height: 100%;
    width: 90%;
    padding: 20px;
    gap: 20px;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
        flex-direction: row;
    }
`;

export const Topbar =  styled.div`
    background: var(--blue);
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: flex-start;  
    justify-content: flex-start;    
    height: 100px;
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;

export const Textbar = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--white);
    margin-left: 20px;
    font-size: 2em;
    @media only screen and (min-width: 1024px) {
        font-size: 2.5em;
    }
    font-weight:800;
`;

export const Pathbar = styled.div`
    background: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: flex-start;  
    justify-content: flex-start;    
    height: 30px;
    width: 90%;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;

export const Textpath = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--black);
    font-size: 0.9em;
    margin-left: 20px;
`;

export const TextLink = styled.div`
    color: var(--black);
    font-size: 0.9em;
    margin-left: 20px;
    :hover {
        font-weight: bold;
    }
`;

export const Arrowright = styled.img`
    margin-left: 2px;
    margin-right: -17px;
    margin-top: -1.5px;
`;

export const Ytplayer = styled.div`
    overflow: hidden;    
    position: relative;
    height: 100%;
    width: 100%;
`;

export const Playlistsection = styled.div`
    background: var(--black);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    @media only screen and (min-width: 1024px) {
        width: 75%;
    }
`;

export const PlaylistBar = styled.div`
    background: var(--blue);
    width: 100%;
    height: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
`;

export const PlaylistTitles = styled.div`
    color: transparent;
    background: var(--black);
    text-shadow: 0 0 0 var(--white);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    overflow: auto;
`;

export const PlaylistBarTextTitle = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--white);
    font-size: 1.5em;
    text-align: center;
`;

export const PlaylistBarText = styled.div`
    font-size: 1.0em;
    padding: 10px 4px;
    color: transparent;
    text-shadow: 0 0 0 var(--white);
    :hover {
        font-weight: bold;
    }
`;

export const PlaylistYTIcon = styled.img`
    width: 100px;
    color: transparent;
    margin-top: 10px;
`;

export const LoaderBiggerWrapper = styled.div`
    position: relative;
    width: 100%;
    padding-top: 56.25%;
`

export const LoaderWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
`;
