import styled from "styled-components";

export const Wrapper = styled.div`  
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
`;

export const Content =  styled.div`
    background: var(--white);
    min-height: var(--minHeight);
    display: flex;
    flex-wrap: no-wrap;
    flex:1;
    flex-direction: column;      
    justify-content: flex-start;
    width: 90%;
    padding: 20px;
    gap: 20px;
    @media only screen and (min-width: 768px) {
        width: 80%;
        flex-direction: row;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;

export const MainContent = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
`;

export const Topbar =  styled.div`
    background: var(--blue);
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 10px 20px;
    width: 90%;
    row-gap: 10px;
    column-gap: 40px;
    flex-wrap: wrap;
    @media only screen and (min-width: 768px) {
        width: 80%;
        flex-direction: row;
        min-height: 80px;
        align-items: center;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
        min-height: 100px;
    }
    
`;

export const Pathbar = styled.div`
    background: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;  
    align-content: flex-start;  
    justify-content: flex-start;    
    min-height: 30px;
    flex-wrap: wrap;
    width: 90%;
    padding: 5px 20px 0 20px;
    gap: 5px;
    @media only screen and (min-width: 768px) {
        width: 80%;
    }
    @media only screen and (min-width: 1024px) {
        width: 70%;
    }
`;


export const Textbar = styled.div`
    color: var(--black);
    font-size: 2em;
    @media only screen and (min-width: 1024px) {
        font-size: 2.5em;
    }
    font-weight:800;
`;

export const Textbarwhite = styled.div`
    color: var(--white);
    font-size: 2em;
    @media only screen and (min-width: 1024px) {
        font-size: 2.5em;
    }
    font-weight:800;
`;

export const Text = styled.div`
    color: var(--black);
    font-size: 0.9em;
`;

export const ListElement = styled.div`
    color: var(--black);
    font-size: 0.9em;
    :hover {
        color: var(--grey);
    }
`;

export const TextLink = styled.div`
    color: var(--black);
    font-size: 0.9em;
    :hover {
        color: var(--blue);
    }
    font-weight:500;
`;

export const CategoryList = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 200px;
    @media only screen and (min-width: 1024px) {
        min-width: 300px;
    }
    gap: 4px;
`;

export const NewsSheetWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`

export const NewsSheetContainer = styled.div`
    position: relative;
    width: 100%;
    padding-top: 60%;
`

export const NewsSheet = styled.div`
    // border: 2px solid blue;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const SubCategories = styled.div`
    background: var(--white);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const Square = styled.div`
    background: var(--blue);
    height: 70px;
    width: 160px;
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center; 
    text-align: center;
    :hover {
        cursor: pointer;
        background: var(--darkBlue);
    }
`;

export const Textsquare = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--white);
    font-size: 1em;
    font-weight: 700;
`;

export const CategoryImg = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--white);
`;

export const Grid = styled.div`
    background: var(--white);
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
`;

export const Icon = styled.img`    
    width: auto;    
    max-width: 100%; 
    height: auto;    
    max-height: 100%;
    padding: 0.8% 2.3% 0.8% 2.3%;    
    margin-bottom: 0px;
`;

export const Border = styled.div`
    border: 2px solid var(--blue);
    height: 160px;
    min-height:160px;
    width: 160px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 0 5px 0;
`;

export const Product = styled.div`
    display: flex;
    flex-direction: column;
    height: 180px;
    width: 160px;
    margin-bottom:25px;
`;

export const ProductText = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--black);
    font-size: 0.7em;
    font-weight: bold;
    margin-bottom: 10px;
    width: 100%;
`;

export const HorLine = styled.div`
    border-top: 2px solid var(--blue);
`

export const CategoryText = styled.div`
    color: transparent;
    text-shadow: 0 0 0 var(--red);
    font-size: 1.5em;
    font-weight: bold;
    color: transparent;
    margin-bottom: 20px;
`;