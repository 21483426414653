import React from 'react';

import Header from './Header';

import Topbar from './TopBar';

import Footer from './Footer';

import ContestRegulationspage from "./ContestRegulationspage";

const ContestRegulations = () => {
    
    return(
        <div>
            <Header/>
            <Topbar/>
            <ContestRegulationspage/>
            <Footer/>
           
        </div>
        
    );
};

export default ContestRegulations;