import React from 'react';

import Header from './Header';

import Topbar from './TopBar';

import Footer from './Footer';

import Shoppage from './Shoppage';

const Shop = () => {
    return(
        <div>
            <Header/>
            <Topbar/>
            <Shoppage/>
            <Footer/>
           
        </div>
        
    );
}

export default Shop;