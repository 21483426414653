import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import { Wrapper, Content, Textbar, Topbar, Pathbar, Textpath, TextLink,
    Arrowright, MapArea, SearchBar, SearchBarText, Searchlistsection } from './Shop.styles';
import Arrow from '../../images/arrow_right_blue.png';
import Mapp from './mapp';
import Loading from '../Loading'




class Shoppage extends Component{
    constructor(props) {
        
        super(props);    
        this.state = {
            isLoading: true,
            stores: [],            
        }
      }
      async componentDidMount(){
        const url = 'https://lorpio-back.ang3r.pl/api/shops';
        const response = await fetch(url); 
        const data = await response.json();                      
        this.setState({
            stores: data.shops,
            isLoading: false
        });
                
    }
    
    render(){        
        return(
            this.state.isLoading
            ?
                <Loading />
            :
                <Wrapper>
                    <Topbar>
                        <Textbar>ZNAJDŹ SKLEP</Textbar>
                    </Topbar>
            
                    <Pathbar>
                        <Link to={`/`}  style={{ textDecoration: 'none' }}>
                            <Textpath>HOME</Textpath>
                        </Link>
                        <Arrowright src={Arrow} alt='arrow'/>
                        <Textpath>ZNAJDŹ SKLEP</Textpath>
                    </Pathbar>
                    
                        {/* <MapArea>
                            <Mapp props={this.state.stores}></Mapp>
                        </MapArea>
                        
                        <Searchlistsection>
                            <SearchBar>
                                <SearchBarText>WPROWADŹ MIEJSCOWOŚĆ LUB KOD POCZTOWY</SearchBarText>
                            </SearchBar>
                        </Searchlistsection> */}
                        <Mapp props={this.state.stores}></Mapp>
                    
                </Wrapper>
        )
    }
}

export default Shoppage;