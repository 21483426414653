import React from 'react';

import Header from './Header';

import Topbar from './TopBar';

import Footer from './Footer';

import Categorypage from './Categorypage';

const Category = () => {  
    
    return(
        <div>
            <Header/>
            <Topbar/>
            <Categorypage/>
            <Footer/>
           
        </div>
        
    );
};

export default Category;