import React from 'react';

import Header from './Header';

import Topbar from './TopBar';

import Footer from './Footer';

import Productspage from './Productspage';

const Products = () => {  
    
    return(
        <div>
            <Header/>
            <Topbar/>
            <Productspage/>
            <Footer/>
           
        </div>
        
    );
};

export default Products;