import React from 'react';

import Header from './Header';
import Baner from './Baner';
import Topbar from './TopBar';
import Homepage from './Homepage';
import Footer from './Footer';

const Home = () => {  
    
    return(
        <div>
            <Header/>
            <Topbar/>
            <Baner/>
            <Homepage></Homepage>
            <Footer/>
           
        </div>
        
    );
};

export default Home;