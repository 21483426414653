import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;800&display=swap');

  :root {
    --maxWidth: 1920px; 
    --maxHeight: 1280px;
    --minHeight: 800px;
    --white: #fff;
    --black: #000;
    --red: #f53333;
    --lightGrey: #cbcbcb;
    --grey: #999;
    --medGrey: #383838; 
    --darkGrey: #1c1c1c;
    --blue: #2885c7;
    --darkBlue: #1b5783;
    --fontSuperBig: 2.5rem;
    --fontBig: 1.5rem;
    --fontMed: 1.2rem;
    --fontSmall: 1rem;
    background: var(--medGrey);
    
  }
  
  table {
  display:block;
    width:100%;
    overflow-y: scroll;
  }
  
  th, td {
    //padding:40px;
    min-width: 160px;
  }
  
  td > p {
    padding:40px;
  }
  
  
  * {
    box-sizing: border-box;
  }
  body {  
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif; 

    h1 {
      font-size: 2rem;
      font-weight: 600;
      color: var(--white);
    }
    h3 {
      font-size: 1.1rem;
      font-weight: 600;
    }
    p {
      font-size: 1rem;
      color: var(--white);
    }
  }

  table p {
    word-break: break-all;
  }
`;