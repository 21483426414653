import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { Wrapper, Content, Textbar, Arrowright, Topbar, Icon,  
    Product, ProductText, Pathbar, CategoryBar, Textpath, TextLink,
    CategoryText, CategoryTextLink, Grid, Border } from './Newspage.styles';
import Arrow from '../../images/arrow_right_blue.png';
import Loading from '../Loading';

const url='https://lorpio-back.ang3r.pl/api/new_products';
class Newspage extends Component{
    constructor(props){
        super(props)
        this.state={
            isLoading: true,
            product:[],
            unique_category: []
        }        
    }
    async componentDidMount(){
        const response = await fetch(url);
        const data = await response.json();        
        const distinctItems = [...new Map(data.new_products.map(item => [item["category_title"], item])).values()]; 
        this.setState({
            product: data.new_products,
            unique_category: distinctItems,
            isLoading: false,
        });
    }
    render(){
        return(
            this.state.isLoading
            ?
                <Loading />
            :
                <Wrapper>
                    <Topbar>
                        <Textbar>NOWOŚCI</Textbar>
                    </Topbar>

                    <Pathbar>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <TextLink>HOME</TextLink>
                        </Link>
                        <Arrowright src={Arrow} alt='arrow'/>
                        <Textpath>NOWOŚCI</Textpath>
                    </Pathbar>
                    
                    <Content>
                        {this.state.unique_category.map((item)=>{
                            return(
                                <>
                                    <CategoryBar key={item.category_id}>
                                        <CategoryText style={{color:'transparent', 'text-shadow':'0 0 0 var(--red)'}} >NOWOŚCI</CategoryText>
                                        <Link to={`/Products/${item.category_id}`} key={item.title} style={{ textDecoration: 'none'}}>
                                            <CategoryTextLink >{item.category_title}</CategoryTextLink>
                                        </Link>
                                    </CategoryBar>
                                    <Grid>
                                    {this.state.product.map((item_product)=>{
                                            if(`${item.category_title}`===`${item_product.category_title}`)
                                            {
                                                return(
                                                    console.log(item_product.images[0]),
                                                    <Link to={`/Product/${item_product.product_id}`} key={item.title} 
                                                    style={{ textDecoration: 'none'}}>
                                                    <Product key={item_product.title}>
                                                        <Border>
                                                            <Icon src={'https://lorpio-back.ang3r.pl'+item_product.images[0]} alt='product_icon' ></Icon>
                                                        </Border>
                                                        
                                                        <ProductText>{item_product.title}</ProductText>
                                                    </Product>    
                                                    </Link>                                        
                                                )
                                            }                                        
                                        })
                                        }
                                    </Grid>
                                </>
                            )
                        })}      
                    </Content>
                </Wrapper> 
        )
    }
}
export default Newspage;


