import styled from "styled-components";

export const Wrapper = styled.div`
  background: var(--blue);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: right;
  align-content: right;
  justify-content: flex-end;
  max-width: var(--maxWidth);
  padding: 10px 0;
  margin: 0 auto;
  width: 90%;
  @media only screen and (min-width: 768px) {
    width: 80%;
  }
  @media only screen and (min-width: 1024px) {
    width: 70%;
  }
`;

export const LogoFb = styled.img`
  width: 30px;
  height: 30px;
  color: transparent;
  padding-right: 8px;
`;

export const LogoLn = styled.img`
  width: 30px;
  height: 30px;
  padding-right: 8px;
  color: transparent;
`;

export const LogoYt = styled.img`
  width: 30px;
  height: 30px;
  color: transparent;
`;

export const LogoTt = styled.img`
  width: 25px;
  height: 25px;
  color: transparent;
  align-self: center;
  padding-left: 3px;
  margin-top: 2px;
`;
