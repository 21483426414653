import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Wrapper, Content, Textbar, Topbar, Pathbar, 
Textpath, TextLink, Arrowright, ContactSection, TextContact, SocialSection,
SocialLine, TextSocial, LogoFb, LogoYt, LogoIg, FormSection, 
SearchSection, SearchTextBlack, SearchTextBlue, MapArea,
SearchButton, NewlineTextWrapper } from './Contact.styles';
import Form from './Form';
import Arrow from '../../images/arrow_right_blue.png';
import FbLogo from '../../images/LOGO_FACEBOOK.svg';
import InstLogo from '../../images/INSTA.svg';
import YtLogo from '../../images/youtube.png';
import QRCode from '../../images/qr.png';
import Map from './Map';
import Loading from '../Loading';

function NewlineText(props) {
    const text = props.text;
    const newText = text.split('\n').map(str => <p style={{color: 'transparent', textShadow: '0 0 0 var(--black)', margin:'0 0 -1px 0', 'font-size':'1.0em'}}>{str}</p>);
    return <div title="normal">{newText}</div>;
}

function NewlineTextNoWrap(props) {
    const text = props.text;
    let newText = text.replaceAll(': ', ':\n');
    newText = newText.split('\n').map(str => <p style={{color: 'transparent', textShadow: '0 0 0 var(--black)', margin:'0 0 -1px 0', 'font-size':'1.0em'}}>{str}</p>);
    return <div title="nowrap">{newText}</div>;
}

const url='https://lorpio-back.ang3r.pl/api/contact';
class Contactpage extends Component{

    constructor(props){
        super(props)
        this.state={
            isLoading: true,
            contact:[],
            numbers: '',
            fb:'',
            ig:'',
            yt:''           
        }
        
    }
    async componentDidMount(){
        const response = await fetch(url);
        const data = await response.json();
        
        this.setState({
            contact: data.contact,  
            numbers: data.contact.phoneNumbers,
            fb: data.contact.facebookURL,
            ig: data.contact.linkedinURL,
            yt: data.contact.youtubeURL,
            isLoading: false,
        })
    }

    

    render(){
        return(
            this.state.isLoading
            ?
                <Loading />
            :
                <Wrapper>
                    <Topbar>
                        <Textbar>KONTAKT</Textbar>
                    </Topbar>

                    <Pathbar>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <TextLink>HOME</TextLink>
                        </Link>
                        <Arrowright src={Arrow} alt='arrow'/>
                        <Textpath>KONTAKT</Textpath>
                    </Pathbar>
                    <Content>
                        <ContactSection>
                            <TextContact>
                                <div style={{color: '#000', fontSize: '1.6em', paddingBottom: '10px'}}>{this.state.contact.companyName} </div><br/>
                                <div style={{fontWeight: '800'}}>SIEDZIBA</div>
                                ul. {this.state.contact.streetAndNumber} <br/>
                                {this.state.contact.postcode} {this.state.contact.city} <br/><br/>
                                <img style={{maxWidth: '150px'}} src={QRCode} alt='qr-code-lorpio'/>
                                <div>Zeskanuj kod, aby pobrać dane</div>
                                <br/>
                                <br/>
                                <div style={{fontWeight: '800'}}>DANE KONTAKTOWE</div>
                                <NewlineTextWrapper>
                                    <NewlineText text={this.state.numbers} />
                                    <NewlineTextNoWrap text={this.state.numbers} />
                                </NewlineTextWrapper>
                                <br/>
                                <div style={{fontWeight: '800'}}>DANE REJESTROWE</div>
                                <div>NIP: 9731062463</div>
                                <div>REGON: 382311830</div>
                                <div>KRS: 0000766345</div>

                            </TextContact>
                            
                            <SocialSection>
                                <a href={this.state.fb}>
                                    <SocialLine>
                                        <LogoFb src={FbLogo} alt='fb-logo'></LogoFb>
                                        <u style={{ textDecorationColor: "gray" }}>
                                            <TextSocial>LORPIOFishingPL</TextSocial>
                                        </u>
                                    </SocialLine>
                                </a>
                                <a href={this.state.yt}>
                                    <SocialLine>
                                        <LogoYt src={YtLogo} alt='yt-logo'></LogoYt>
                                        <u style={{ textDecorationColor: "gray" }}>
                                            <TextSocial>LORPIOWebTV</TextSocial>
                                        </u>
                                    </SocialLine>
                                </a>
                                <a href={this.state.ig}>
                                    <SocialLine>
                                        <LogoIg src={InstLogo} alt='ln-logo'></LogoIg>
                                        <u style={{ textDecorationColor: "gray" }}>
                                            <TextSocial>LORPIOFishing</TextSocial>
                                        </u>
                                    </SocialLine>
                                </a>
                            </SocialSection>
                        </ContactSection>

                        <FormSection>
                            <Form></Form>
                            <SearchSection>
                                <SearchTextBlue>WYSZUKIWARKA SKLEPÓW</SearchTextBlue>
                                <SearchTextBlack>Wyszukaj sklep posiadający ofertę LORPIO.</SearchTextBlack>
                                <Link to='/Shop' style={{textDecoration:'none'}}><SearchButton>ZNAJDŹ SKLEP</SearchButton></Link>
                            </SearchSection>
                        </FormSection>
                        


                        <MapArea>
                            <Map style={{border:'border: 2px solid var(--blue);'}}></Map>
                        </MapArea>
                    </Content>
                </Wrapper>
        )
    }
}

export default Contactpage;

