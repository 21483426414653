import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/styles";
import styled from 'styled-components';

export default function Loading({noAdditionalHeight = false, color = 'var(--blue)'}) {
  const Wrapper = styled.div`
    display: flex;
    ${noAdditionalHeight ? '' : 'height: 60vh;'}
    align-items: center;
    justify-content: center;
  `

  const CustomLoading = withStyles((theme) => ({
    colorPrimary: {
      color: color,
    }
  }))(CircularProgress)

  return (
    <Wrapper>
      <CustomLoading />
    </Wrapper>
  )
}
