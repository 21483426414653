import styled from "styled-components";

export const BiggerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 100%;
  background: var(--blue);
  position: relative;
  padding: 0 5%;
  @media only screen and (min-width: 768px) {
    padding: 0 10%;
  }
  @media only screen and (min-width: 1024px) {
    padding: 0 15%;
  }
`;

export const Wrapper = styled.div`
  background: var(--blue);
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const Content = styled.div`
  font-family: "Arial", sans-serif !important;
  max-width: var(--maxWidth);
  height: 100%;
  width: 100%;
  padding: 20px 0;
  margin-bottom: 5px;
  display: grid;
  row-gap: 20px;
  align-items: center;
  grid-template-areas:
    "logo"
    "contact"
    "toolbar"
    "socials";
  @media only screen and (min-width: 890px) {
    grid-template-columns: 260px 1fr;
    grid-template-areas:
      "logo contact"
      "socials toolbar";
  }
`;

export const LeftBar = styled.div`
  width: 15%;
  height: 100%;
  background: var(--blue);
  display: flex;
  flex-direction: row;
`;

export const RightBar = styled.div`
  background: var(--blue);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end;
  width: 15%;
  height: 170px;
`;

export const Arrow = styled.img`
  display: flex;
  flex-direction: row;
  color: transparent;
  width: 100%auto;
  height: 30px;
  cursor: pointer;
  margin: 0 40px 20px 0;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const Logosection = styled.img`
  grid-area: logo;
  display: flex;
  align-self: start;
  width: 230px;
  height: 80px;
  object-fit: contain;
`;

export const Socialsection = styled.div`
  grid-area: socials;
  display: flex;
  flex-direction: row;
  margin-right: 24px;
  align-items: center;
`;

export const Contactsection = styled.div`
  grid-area: contact;
  color: transparent;
  text-shadow: 0 0 0 var(--white);
  display: flex;
  flex-direction: column;
  flex-grow: 3;
  line-height: 1.3;
  padding-left: 6px;
  div[title="normal"] {
    display: none;
  }
  @media only screen and (min-width: 500px) {
    div[title="normal"] {
      display: block;
    }
    div[title="nowrap"] {
      display: none;
    }
  }
`;

export const Toolbarsection = styled.div`
  display: grid;
  grid-area: toolbar;
  row-gap: 8px;
  @media only screen and (max-width: 500px) {
    padding-left: 6px;
  }
  @media only screen and (min-width: 500px) {
    grid-template-columns: 150px 150px 150px;
    padding-left: 6px;
  }
  @media only screen and (min-width: 1250px) {
    display: flex;
    flex-grow: 3;
    flex-direction: row;
    justify-content: start;
    gap: 25px;
  }
`;

export const LogoFb = styled.img`
  width: 30px;
  height: 30px;
  padding-right: 8px;
  color: transparent;
`;

export const LogoIn = styled.img`
  width: 30px;
  height: 30px;
  padding-right: 8px;
  color: transparent;
`;

export const LogoYt = styled.img`
  width: 30px;
  padding-right: 8px;
  color: transparent;
`;

export const Topsection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  align-content: left;
  margin-top: 30px;
  margin-bottom: 25px;
`;

export const Bottomsection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;
  align-content: left;
`;

export const Text = styled.div`
  color: transparent;
  text-shadow: 0 0 0 var(--white);
  font-size: 1em;
  white-space: nowrap;
  :hover {
    font-weight: bold;
  }
`;

export const LogoTt = styled.img`
  width: 25px;
  height: 25px;
  color: transparent;
  align-self: center;
`;
