import React, {Component} from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Thumbs } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { Wrapper, Content, Text, Textbar,
     Topbar, Pathbar, Textbarwhite, ImgSection, DescriptionSection, DescriptionTopBar, TextLink,
     ItemName, BrandNameText, ItemNameText, ImgBorder, NewsImg, DescriptionText} from './ProductContainer.styles';
import Arrow from '../../images/arrow_right_blue.png';
import {Link} from 'react-router-dom';
import Swiperr from './Swiper';
import Loading from '../Loading';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Thumbs]);
const url = 'https://lorpio-back.ang3r.pl/api/products/1';

class Productpage extends Component{
    
    constructor(props){
        super(props)
        this.state={    
            isLoading: true,                
            title: [],
            producent: [],
            description: [],
            images: [],
            new: Boolean
        }        
    }
    async componentDidMount(){
        await fetch(url);    // looks like it doesnt work without this                   
        const current_id = window.location.href.split('/')[4]         
        const url2 = 'https://lorpio-back.ang3r.pl/api/products/' + current_id        
        const response = await fetch(url2);
        const data = await response.json();
        this.setState({
            title:  data.title,
            producent:  data.producent,
            description:  data.description,
            images:  data.images,
            new:  data.new,
            isLoading: false
        })
    }
    
    
    render(){
        
        return(
            this.state.isLoading
            ?
                <Loading />
            :
                <Wrapper>
                    <Topbar>
                        <Textbar>                        
                                <Text style={{'font-weight':'800', 'margin-left':'0px', 'font-size':'1.0em'}}>{this.state.new? 'NOWOŚCI': 'PRODUKTY'}</Text>
                        </Textbar>
                        <Textbarwhite>{this.state.title}</Textbarwhite>   
                    </Topbar>
                    <Pathbar>
                        <Link to="/" style={{ textDecoration: 'none' }}>
                            <TextLink>HOME</TextLink>
                        </Link>
                        <img src={Arrow} alt='arrow'/>
                        <Link to={this.state.new? '/News' : '/Products'} style={{ textDecoration: 'none' }}>
                            <TextLink>{this.state.new? 'NOWOŚCI': 'PRODUKTY'}</TextLink>
                        </Link>
                        <img src={Arrow} alt='arrow'/>
                        <Text>{this.state.title}</Text>
                    </Pathbar>
                    <Content>
                        <ImgSection>
                        {/* <Swiper navigation pagination={{clickable: true}} 
                        onSwiper={(swiper) => console.log(swiper) } 
                        onSlideChange={() => console.log('slide change')}
                        style={{width:'100%', display: 'flex', 'flex-direction':'row', 'align-content':'center', 'justify-content': 'center'}}>   
                                {this.state.images.map((item, index)=>{
                                    return(
                                        console.log(item),
                                        //console.log('https://lorpio-back.ang3r.pl'+item),
                                        
                                        <SwiperSlide key={index} style={{ display: 'flex', 'flex-direction':'row', 'align-content':'center', 'justify-content': 'center'}}> 
                                                                
                                            <ImgBorder>
                                                <NewsImg src={'https://lorpio-back.ang3r.pl'+item} alt='product_icon' />
                                            </ImgBorder>  
                                        </SwiperSlide>  
                                        
                                    )                         
                                })                            
                                } 
                        </Swiper>                     */}
                            <Swiperr images={this.state.images}></Swiperr>
                        </ImgSection>       
                                                
                        
                        <DescriptionSection>
                            <DescriptionTopBar>
                                <ItemName>
                                    <BrandNameText>{this.state.producent}</BrandNameText>
                                    <ItemNameText>{this.state.title}</ItemNameText>
                                </ItemName>
                                {/* <NewsImg></NewsImg> */}
                            </DescriptionTopBar>

                            <div style={{}}>
                                <DescriptionText dangerouslySetInnerHTML={{ __html: this.state.description }}></DescriptionText>
                            </div>
                        </DescriptionSection>
                    </Content>
                </Wrapper>
        )
    }
}

export default Productpage;