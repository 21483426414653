import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import { Wrapper, Content, Text,  Arrowright, Textbar, TextLink,
    Textsquare, Square, Topbar, Pathbar, Grid } from './Products.styles';
import Arrow from '../../images/arrow_right_blue.png';
import Loading from '../Loading';


const url = "https://lorpio-back.ang3r.pl/api/categories/";

function Productpage() {
    const [category, setCategory] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect( async () => {
        const response = await fetch(url);
        const data = await response.json();
        setCategory(data.zeroLevelCategories)
        setIsLoading(false)
    }, [])


    const ContentToLoad = () => ( 
        <Wrapper>
            <Topbar><Textbar>PRODUKTY</Textbar></Topbar>
            <Pathbar>
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <TextLink>HOME</TextLink>
                </Link>
                <Arrowright src={Arrow} alt='arrow'/>
                <Text>PRODUKTY</Text>
            </Pathbar>
            <Content>
                    <Grid>                            
                        {category.map((item) => {
                            if (item.title !== "NOWOŚCI") {
                                return(     
                                    <Link to={`/Products/${item.id}`} key={item.title} style={{
                                        textDecoration: 'none', 
                                    }}>
                                        <Square >
                                            <Textsquare >{item.title}</Textsquare>
                                        </Square>                   
                                    </Link> 
                                )
                            }
                        })}
                    </Grid>
            </Content>
        </Wrapper>
    )

    return(
        (isLoading) ?
            <Loading />
        :
            <ContentToLoad />   
    )
}

export default Productpage;